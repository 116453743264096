import { createSlice } from '@reduxjs/toolkit';
import AbstractSliceHandler from 'modules/shared/stores/abstract-slice-handler';
import LocalEvent from 'utils/local-event';
import Log from 'utils/log';
import sdkClient from 'utils/sdk-client';

const initialState = {
    /**
     * The details about who is logged in.
     * @type JsonUser
     */
    loggedInUser: {
        isGroup: false,
        isGuest: false,
        contactId: wcfUiCfg.msisdn,
        msisdn: wcfUiCfg.msisdn,
        fullName: wcfUiCfg.msisdn,
    },
};

class ContactsSliceHandler extends AbstractSliceHandler {
    static getInstance() {
        if (!ContactsSliceHandler.instance) {
            ContactsSliceHandler.instance = new ContactsSliceHandler();
        }

        return ContactsSliceHandler.instance;
    }

    constructor() {
        super('contactsSlice');

        LocalEvent.setListener(LocalEvent.eventTypes.SDK_CLIENT_AUTHENTICATED, (event) => {
            this.onSdkClientAuthenticated(event);
        });

        this.slice = createSlice({
            name: this.sliceName,
            initialState,
            reducers: {
                /**
                 * Set the data about the logged-in user.
                 * @param {object} state
                 * @param {{payload: JsonContact}} action
                 */
                setLoggedInUser(state, action) {
                    state.loggedInUser = action.payload;
                },
            },
        });
    }

    /**
     * @return {JsonUser}
     */
    getLoggedInUser = () => this.getOwnState().loggedInUser;

    /**
     * Get the details about a user, when the msisdn is known.
     * @return {Promise<JsonUser|null>}
     */
    getUserDetails = (msisdn) =>
        new Promise((resolve) => {
            webchatSDK.STWContactManager.searchSingleContact(msisdn)
                .then((sdkUser) => {
                    resolve(sdkClient.sdkEntityToJsonContact(sdkUser));
                })
                .catch(
                    /**
                     * @param {SdkError} sdkError
                     */
                    (sdkError) => {
                        Log.warn('CONTACTS STORE', `There was an error loading user details: ${sdkError.err}`);

                        resolve(null);
                    },
                );
        });

    /**
     * @param {CustomEvent} event
     */
    onSdkClientAuthenticated = (event) => {
        this.dispatch('setLoggedInUser', event.detail[0]);
    };
}

// the handler
export const contactsSliceHandler = ContactsSliceHandler.getInstance();
// the selectors to be used in useSelect
export const selectLoggedInUser = (state) => state.contactsSlice.loggedInUser;
export const selectLoggedInMsisdn = (state) => state.contactsSlice.loggedInUser.msisdn;
export const { getLoggedInUser } = contactsSliceHandler;
