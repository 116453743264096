import React, { lazy, Suspense } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Log from './log';

/**
 * Generate a random uuid.
 * @return {string}
 */
export function randUuid() {
    // our cor wants the UUIDS win upper case
    return crypto.randomUUID().toUpperCase();
}

/**
 * Sort an array of elements by the given property.
 * @param {object[]} arr
 * @param {string} propertyName
 * @param {boolean} reverse
 */
export function sortByProperty(arr, propertyName, reverse = false) {
    // sanity
    if (typeof arr !== 'object' || arr.length <= 1 || ['string', 'number'].indexOf(typeof arr[0][propertyName]) < 0) {
        return;
    }

    const applySorting = (objA, objB) => {
        const valA = String(objA[propertyName]).toUpperCase();
        const valB = String(objB[propertyName]).toUpperCase();

        let result = 0;

        if (valA < valB) {
            result = -1;
        } else if (valA > valB) {
            result = 1;
        } else {
            result = 0;
        }

        if (reverse) {
            result *= -1;
        }

        return result;
    };

    arr.sort(applySorting);
}

/**
 * Put the first letter in lower case.
 * @param str
 */
export function lcFirst(str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
}

/**
 * Import on the fly a component.
 * @param {LazyImportOptions} options
 */
export const lazyImportComponent = (options) => {
    // sanity
    if (typeof options.componentPath === 'undefined' || options.componentPath.length === 0) {
        return '';
    }

    const ComponentToRender = lazy(() => import(`../modules/${options.componentPath}`));

    const componentProperties = typeof options.componentProperties !== 'undefined' ? options.componentProperties : {};

    return (
        <Suspense fallback={<CircularProgress />}>
            <ComponentToRender {...componentProperties} />
        </Suspense>
    );
};

/**
 * Load a JSON from the given url.
 * @param jsonUrl
 * @return Promise<object>
 */
export const fetchJson = (jsonUrl) =>
    new Promise((resolve, reject) => {
        // thank you https://developer.mozilla.org/
        let fetchStatus;
        fetch(jsonUrl, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json;charset=UTF-8',
            },
        })
            .then((response) => {
                // Save the response status in a variable to use later.
                fetchStatus = response.status;
                // Handle success
                // eg. Convert the response to JSON and return
                return response.json();
            })
            .then((json) => {
                // Check if the response were success
                if (fetchStatus === 200) {
                    // Use the converted JSON
                    resolve(json);
                } else {
                    Log.error('FETCH', `Bad status received for JSON url ${jsonUrl}: ${fetchStatus}`);
                    reject();
                }
            })
            .catch((error) => {
                // Catch errors
                Log.error('FETCH', `There was an error while loading the JSON from url: ${jsonUrl}`);
                console.log(error);

                reject();
            });
    });
