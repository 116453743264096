import { createSlice } from '@reduxjs/toolkit';
import AbstractSliceHandler from 'modules/shared/stores/abstract-slice-handler';

const initialState = {
    toasters: [],
};

/**
 * Extract an unique id from toaster options.
 * @param {ToasterOptions} toasterOptions
 */
const toasterOptionsToId = (toasterOptions) => btoa(encodeURI(`${toasterOptions.message}-${toasterOptions.type}`));

const removeToasterFromList = (toastersList, idToRemove) =>
    [...toastersList].filter((toaster) => {
        // when toaster is removed must cancel its hide timeout
        if (toaster.id === idToRemove) {
            clearTimeout(toaster.autoHideTimer);
            return false;
        }

        return true;
    });

class ToastersSliceHandler extends AbstractSliceHandler {
    static getInstance() {
        if (!ToastersSliceHandler.instance) {
            ToastersSliceHandler.instance = new ToastersSliceHandler();
        }

        return ToastersSliceHandler.instance;
    }

    constructor() {
        super('toastersSlice');

        this.slice = createSlice({
            name: this.sliceName,
            initialState,
            // The `reducers` field lets us define reducers and generate associated actions
            reducers: {
                /**
                 * Register a portal.
                 * @param {object} state
                 * @param {{payload: ToasterOptions}} action
                 */
                addToasterAction(state, action) {
                    const id = toasterOptionsToId(action.payload);
                    const newToaster = { id, ...action.payload };

                    newToaster.onClose = () => {
                        ToastersSliceHandler.getInstance().dispatch('removeToasterAction', action.payload);
                    };

                    const duration = action.payload.duration ?? 5;

                    newToaster.hideTimer = setTimeout(() => {
                        newToaster.onClose();
                    }, duration * 1000);

                    const currentToasters = removeToasterFromList(state.toasters, id);

                    currentToasters.unshift(newToaster);

                    state.toasters = [...currentToasters];
                },
                /**
                 * Remove a registered portal.
                 * @param {object} state
                 * @param {{payload: ToasterOptions}} action
                 */
                removeToasterAction(state, action) {
                    const id = toasterOptionsToId(action.payload);

                    const currentToasters = removeToasterFromList(state.toasters, id);

                    state.toasters = [...currentToasters];
                },
            },
        });
    }

    /**
     * @param {ToasterOptions} toasterOptions
     */
    addToasterToStore = (toasterOptions) => {
        this.dispatch('addToasterAction', toasterOptions);
    };

    /**
     * @param {ToasterOptions} toasterOptions
     */
    removeToasterFromStore = (toasterOptions) => {
        this.dispatch('removeToasterAction', toasterOptions);
    };
}

// the handler
export const toastersSliceHandler = ToastersSliceHandler.getInstance();
export const selectToasters = (state) => state.toastersSlice.toasters;
export const { addToasterToStore, removeToasterFromStore } = toastersSliceHandler;
