import Log from './log';
import LocalEvent from './local-event';
import { lcFirst } from './functions';

/**
 * This class is used to communicate with the WebChat SDK.
 */
class SdkClient {
    static getInstance() {
        if (typeof SdkClient.instance !== 'undefined') {
            return SdkClient.instance;
        }

        SdkClient.instance = new SdkClient();

        return SdkClient.instance;
    }

    constructor() {
        this.authService = null;
    }

    /**
     * (Auto)create the SDK connection.
     */
    init() {
        if (wcfUiCfg.msisdn === null) {
            Log.debug('SDK CLIENT', 'Starting in guest mode as the msidsn is null');
            LocalEvent.trigger(LocalEvent.eventTypes.SDK_CLIENT_READY_TO_AUTHENTICATE);
        } else {
            Log.debug('SDK CLIENT', 'Attempting to validate the user and the token.');
            webchatSDK.STWLoginManager.init(wcfUiCfg.swapiUrlBase, true);
            webchatSDK.STWLoginManager._loginWithTokenFromUI(wcfUiCfg.swapiToken).then(
                () => {
                    Log.debug(
                        'SDK CLIENT',
                        `The user ${wcfUiCfg.msisdn} and its token has been validated. Loading user data ...`,
                    );

                    webchatSDK.STWAccountManager.getUser()
                        .then((sdkEntity) => {
                            LocalEvent.trigger(
                                LocalEvent.eventTypes.SDK_CLIENT_AUTHENTICATED,
                                this.sdkEntityToJsonContact(sdkEntity),
                            );
                        })
                        .catch((error) => {
                            Log.error(
                                'SDK CLIENT',
                                `There was an error while loading logged in user data: ${JSON.stringify(error)}.`,
                            );
                        });
                },
                (err) => {
                    Log.warn('SDK CLIENT', `The user ${wcfUiCfg.msisdn} was not validated due to error: ${err.code}`);
                    LocalEvent.trigger(LocalEvent.eventTypes.SDK_CLIENT_READY_TO_AUTHENTICATE);
                },
            );
        }
    }

    /**
     * Get the CUG settings (rare and frequent).
     * @return Promise<CugSettings>
     */
    getCugSettings() {
        return new Promise((resolve) => {
            resolve(webchatSDK.STWAccountManager.getConfiguration());
        });
    }

    /**
     * Get the Configuration settings that are accessible at subscriber level (and cache them).
     * @param {boolean} useCachedDataIfAny
     * @return Promise<ConfigurationSettings>
     */
    getConfigurationSettings(useCachedDataIfAny = true) {
        return new Promise((resolve, reject) => {
            if (useCachedDataIfAny && 'cachedConfigurationSettings' in this) {
                resolve(this.cachedConfigurationSettings);

                return;
            }

            webchatSDK.STWAPI.getConfigurationSettings()
                .then((sdkResponse) => {
                    const configurationSettings = sdkResponse.data.results;

                    this.cachedConfigurationSettings = configurationSettings;

                    resolve(this.cachedConfigurationSettings);
                })
                .catch((err) => {
                    Log.warn('SDK CLIENT', `There was an error while loading configuration settings: ${err.code}`);
                    reject(err);
                });
        });
    }

    /**
     * Transform an SDK entity group or subscriber to a JsonContact json.
     * @param {SdkContact} sdkEntity
     * @return JsonContact
     */
    sdkEntityToJsonContact(sdkEntity) {
        const jsonContact = {
            ...sdkEntity.toJSON(),
            fullName: sdkEntity.fullName,
            contactId: sdkEntity.msisdn,
            // important. makes sure that the contact item was properly formatted locally
            isJsonContact: true,
        };

        if (!jsonContact.isGroup) {
            return jsonContact;
        }

        // go recursive for groups members
        const jsonMembers = [];
        if (typeof jsonContact.groupMembers !== 'undefined') {
            jsonContact.groupMembers.forEach((sdkMember) => {
                jsonMembers.push(this.sdkEntityToJsonContact(sdkMember));
            });
        }
        // go recursive for child groups
        if (typeof jsonContact.childGroups !== 'undefined') {
            jsonContact.childGroups.forEach((sdkMember) => {
                jsonMembers.push(this.sdkEntityToJsonContact(sdkMember));
            });
        }

        if (typeof jsonContact.isUserGroup === 'undefined') {
            jsonContact.isUserGroup = false;
        }

        if (typeof jsonContact.isUserGroupAdmin === 'undefined') {
            jsonContact.isUserGroupAdmin = false;
        }

        jsonContact.groupMembers = [...jsonMembers];

        return jsonContact;
    }

    /**
     * Transform a Loki entity group or subscriber to a JsonContact json.
     * @param {LokiContact} lokiEntity
     * @return JsonContact
     */
    lokiEntityToJsonContact(lokiEntity) {
        /**
         * @type {JsonContact}
         */
        const jsonContact = {
            isJsonContact: true,
        };

        Object.keys(lokiEntity).forEach((prop) => {
            jsonContact[lcFirst(prop)] = lokiEntity[prop];
        });

        if (typeof jsonContact.groupId !== 'undefined') {
            jsonContact.isGroup = true;
            jsonContact.fullName = jsonContact.name;
            jsonContact.contactId = String(jsonContact.groupId);
        } else {
            jsonContact.isGroup = false;
            jsonContact.fullName = `${jsonContact.firstName} ${jsonContact.lastName}`;
            jsonContact.contactId = jsonContact.msisdn;
        }

        return jsonContact;
    }

    /**
     * Get the Voip config
     * @return Promise<VoipConfig>
     */
    getVoipConfig() {
        return new Promise((resolve, reject) => {
            webchatSDK.STWAPI.getConfigServices()
                .then((response) => {
                    resolve(response.data.results);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    /**
     * Get a new voip token.
     * @return Promise<VoipToken>
     */
    getVoipToken() {
        return new Promise((resolve, reject) => {
            webchatSDK.STWAPI.getVoipToken()
                .then((response) => {
                    resolve(response.data.results.VoipToken);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }
}

export default SdkClient.getInstance();
