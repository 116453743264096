import { createSlice } from '@reduxjs/toolkit';
import Log from 'utils/log';
import LocalEvent from 'utils/local-event';
import sdkClient from 'utils/sdk-client';
import AbstractVoipStackClient from 'modules/shared/stores/abstract-voip-stack-client';
import { contactsSliceHandler } from 'modules/contacts/stores/contacts-slice-handler';

const initialState = {
    /**
     * If the user is allowed to share the video stream.
     */
    sharingEnabled: false,
    streamers: {},
};

const timeCounters = {};

class VideoStreamingSliceHandler extends AbstractVoipStackClient {
    static getInstance() {
        if (!VideoStreamingSliceHandler.instance) {
            VideoStreamingSliceHandler.instance = new VideoStreamingSliceHandler();
        }

        return VideoStreamingSliceHandler.instance;
    }

    constructor() {
        super('videoStreamingSlice');

        LocalEvent.setListener(LocalEvent.eventTypes.SDK_CLIENT_AUTHENTICATED, (event) => {
            this.onSdkClientAuthenticated(event);
        });

        this.slice = createSlice({
            name: this.sliceName,
            initialState,
            reducers: {
                setStreamer(state, action) {
                    const newStreamer = { ...action.payload };

                    state.streamers[action.payload.msisdn] = newStreamer;

                    LocalEvent.trigger(LocalEvent.eventTypes.VIDEO_STREAM_STREAMER_UPDATED, newStreamer);
                },
                removeStreamer(state, action) {
                    // gone already
                    if (typeof state.streamers[action.payload] === 'undefined') {
                        return;
                    }

                    const actualState = state.streamers;
                    delete actualState[action.payload];

                    state.streamers = { ...actualState };
                },
                setSharingEnabled(state, action) {
                    state.sharingEnabled = action.payload;
                },
            },
        });
    }

    /**
     * Connect to a video streaming session.
     * @param {string} msisdn The msisdn of the user who does the streaming
     * @param {string} sessionId
     * @param {HTMLElement} videoElement
     * @return string The internal id which allows accessing the streaming data.
     */
    connectToUserStreaming = (msisdn, sessionId, videoElement) =>
        this.initializeStreamer(msisdn, 'user', videoElement, sessionId);

    /**
     * Connect to a video streaming session.
     * @param {string} msisdn
     * @param {HTMLElement} videoElement
     * @return string The internal id which allows accessing the streaming data.
     */
    connectToCameraStreaming = (msisdn, videoElement) => this.initializeStreamer(msisdn, 'camera', videoElement);

    /**
     * Get the data about certain streamer
     * @param {string} msisdn
     * @return {*}
     */
    getStreamerByMsisdn = (msisdn) => {
        const { streamers } = this.getOwnState();

        if (typeof streamers[msisdn] === 'undefined') {
            return null;
        }

        return { ...streamers[msisdn] };
    };

    /**
     * Get the data about certain streamer when the sessionId is known
     * @param {string} sessionId
     * @return {*}
     */
    getStreamerBySessionId = (sessionId) => {
        const { streamers } = this.getOwnState();
        let streamer = null;

        Object.keys(streamers).forEach((msisdn) => {
            const streamerObj = { ...streamers[msisdn] };

            if (streamer !== null) {
                return;
            }

            if (streamerObj.sessionId === sessionId) {
                streamer = streamerObj;
            }
        });

        return streamer;
    };

    /**
     * Get the data about certain streamer when the sessionId is known
     * @param {SWSIPVOIP2.Call.CEvent} sipEvent
     * @return {*}
     */
    getStreamerByLastUpdatedParticipant(sipEvent) {
        const streamer = this.getStreamerBySessionId(sipEvent.call.getSessionId());

        // should never happen
        if (streamer === null) {
            Log.notice(
                'VIDEO STREAMING SLICE',
                `Cannot get streamer by last participant update because the call ${sipEvent.call.getSessionId()} has gone`,
            );

            return null;
        }

        const msisdn = sipEvent.call.getLastUpdatedParticipant();

        if (streamer.msisdn !== msisdn) {
            // this is another participant
            return null;
        }

        return { ...streamer };
    }

    removeStreamer = (streamerId) => {
        const { streamers } = this.getOwnState();

        if (typeof streamers[streamerId] !== 'undefined') {
            if (streamers[streamerId].voipStack !== null) {
                Log.debug('VIDEO STREAMING SLICE', `Stopping the stack and unregistering streamer: ${streamerId}`);
                streamers[streamerId].voipStack.stop();
            } else {
                Log.debug('VIDEO STREAMING SLICE', `Unregistering streamer with no stack: ${streamerId}`);
            }

            this.dispatch('removeStreamer', streamerId);
        }

        if (typeof timeCounters[streamerId] !== 'undefined') {
            clearInterval(timeCounters[streamerId]);
            delete timeCounters[streamerId];
        }
    };

    /**
     * Internal function which is making the voip stack initialization.
     * @param {string} msisdn
     * @param {'user'|'camera'} sourceType
     * @param {HTMLElement} videoElement
     */
    initializeStreamer = (msisdn, sourceType, videoElement, sessionId = null) => {
        /**
         * @type {VoipStackOptions}
         */
        const stackOptions = {
            rendererRemote: videoElement,
            sessionType: SWSIPVOIP2.Call.SessionType.LIVESTREAM,
            sessionMedia: SWSIPVOIP2.Call.SessionMedia.VIDEO,
        };

        if (sourceType === 'user') {
            stackOptions.sessionId = sessionId;
        } else {
            stackOptions.swTo = `camera:${msisdn}`;
        }

        /**
         * @type {VideoStreamer}
         */
        const streamer = {
            msisdn,
            sessionId,
            sourceType,
            userName: msisdn,
            connected: false,
            connecting: false,
            disconnectedReconnecting: false,
            live: false,
            liveTime: 0,
            voipStack: null,
            stackStopped: false,
            streamingEndedBySource: false,
            hasNewParticipantsInvited: false,
        };

        this.dispatch('setStreamer', streamer);

        Promise.all([contactsSliceHandler.getUserDetails(msisdn), this.initializeVoipStack(stackOptions)]).then(
            /**
             * @param {SWSIPVOIP2.Call|null} voipStack
             */
            ([user, voipStack]) => {
                if (voipStack === null) {
                    Log.error('VIDEO STREAMING SLICE', `Unable to create voip stack for msisdn ${msisdn}`);

                    return;
                }

                streamer.userName = user !== null ? user.fullName : msisdn;
                streamer.connecting = true;

                this.dispatch('setStreamer', streamer);

                voipStack.on(SWSIPVOIP2.Call.Event.STARTED, (sipEvent) => this.onStackStarted(sipEvent, msisdn));
                voipStack.on(SWSIPVOIP2.Call.Event.STOPPED, (sipEvent) => this.onStackStopped(sipEvent));
                voipStack.on(SWSIPVOIP2.Call.Event.DISCONNECTED_AND_RECONNECTING, (sipEvent) =>
                    this.onStackDisconnectedReconnecting(sipEvent),
                );
                voipStack.on(SWSIPVOIP2.Call.Event.SESSION_SUBSCRIBER_INVITATION_OK, (sipEvent) =>
                    this.onParticipantsInvited(sipEvent),
                );

                voipStack.on(SWSIPVOIP2.Call.Event.PARTICIPANT, (sipEvent) => {
                    switch (sipEvent.subtype) {
                        case SWSIPVOIP2.Participant.Event.PARTICIPANT_LEFT:
                        case SWSIPVOIP2.Participant.Event.PARTICIPANT_REFUSED:
                            this.onParticipantLeft(sipEvent);
                            break;

                        case SWSIPVOIP2.Participant.Event.PARTICIPANT_ONHOLD:
                            this.onParticipantOnHold(sipEvent);
                            break;

                        case SWSIPVOIP2.Participant.Event.PARTICIPANT_RESUME:
                            this.onParticipantResume(sipEvent);
                            break;

                        default:
                            // nothing to do for other events
                            break;
                    }
                });

                voipStack.start();
            },
        );
    };

    /**
     * @param {SWSIPVOIP2.Call.CEvent} sipEvent
     * @param {string} msisdn The msisdn for which the streamer has been created.
     */
    onStackStarted = (sipEvent, msisdn) => {
        // check if the streamer is still there; maybe user close the window before actual start
        const streamer = this.getStreamerByMsisdn(msisdn);

        if (streamer === null) {
            Log.notice(
                'VIDEO STREAMING SLICE',
                `Voip stack started but streamer of msisdn ${msisdn} was removed by user`,
            );

            return;
        }

        const liveState =
            streamer.sourceType === 'camera'
                ? true
                : sipEvent.call.getParticipantState(streamer.msisdn) === SWSIPVOIP2.Participant.State.ATTENDING;

        if (streamer.disconnectedReconnecting === false) {
            Log.debug('VIDEO STREAMING SLICE', `Voip stack started for msisdn ${streamer.msisdn}`);

            streamer.connected = true;
            streamer.connecting = false;
            streamer.live = liveState;
            streamer.voipStack = sipEvent.call;
            streamer.sessionId = sipEvent.call.getSessionId();
            this.dispatch('setStreamer', streamer);
            setTimeout(() => {
                this.armTimeCounter(streamer.msisdn);
            });
        } else {
            Log.debug('VIDEO STREAMING SLICE', `Voip stack re-connected for session id ${streamer.sessionId}`);

            streamer.connected = true;
            streamer.connecting = false;
            streamer.disconnectedReconnecting = false;
            streamer.live = true;

            this.dispatch('setStreamer', streamer);
        }
    };

    /**
     * @param {SWSIPVOIP2.Call.CEvent} sipEvent
     */
    onStackStopped = (sipEvent) => {
        const streamer = this.getStreamerBySessionId(sipEvent.call.getSessionId());

        // should never happen
        if (streamer === null) {
            Log.notice(
                'VIDEO STREAMING SLICE',
                `Voip stack stopped but streamer was removed by user for session id ${sipEvent.call.getSessionId()}`,
            );

            return;
        }

        if (streamer.stackStopped === true) {
            // ignore this; stopped was already treated
            return;
        }

        const stopReason = sipEvent.call.getStoppedReason();
        const error = sipEvent.call.getError();

        Log.notice(
            'VIDEO STREAMING SLICE',
            `Voip stack with session id ${sipEvent.call.getSessionId()} stopped. Reason was ${stopReason}, message was ${error}`,
        );

        streamer.connected = false;
        streamer.connecting = false;
        streamer.live = false;
        streamer.stackStopped = true;
        streamer.streamingEndedBySource = [SWSIPVOIP2.Call.StoppedReason.STOPPED_BYE_RECEIVED_INITIATOR_LEFT].includes(
            stopReason,
        );
        this.dispatch('setStreamer', streamer);
    };

    /**
     * @param {SWSIPVOIP2.Call.CEvent} sipEvent
     */
    onStackDisconnectedReconnecting = (sipEvent) => {
        const streamer = this.getStreamerBySessionId(sipEvent.call.getSessionId());

        // should never happen
        if (streamer === null) {
            return;
        }

        streamer.live = false;
        streamer.disconnectedReconnecting = true;
        this.dispatch('setStreamer', streamer);
    };

    /**
     * @param {SWSIPVOIP2.Call.CEvent} sipEvent
     */
    onParticipantsInvited = (sipEvent) => {
        const streamer = this.getStreamerBySessionId(sipEvent.call.getSessionId());

        // should never happen
        if (streamer === null) {
            return;
        }

        Log.debug('VIDEO STREAMING SLICE', `New participants added to call ${streamer.sessionId}`);

        streamer.hasNewParticipantsInvited = true;

        this.dispatch('setStreamer', streamer);
    };

    /**
     * Stop the share for the given msisdn (Kick invited participants)
     * @param {string} msisdn
     */
    stopShare = (msisdn) => {
        const streamer = this.getStreamerByMsisdn(msisdn);

        // should never happen
        if (streamer === null) {
            return;
        }

        if (streamer.voipStack !== null) {
            const participantsData = streamer.voipStack.getLastInvitedParticipantsData();

            if (
                typeof participantsData.newSwto === 'undefined' ||
                participantsData.newSwto === null ||
                participantsData.newSwto.length === 0
            ) {
                Log.notice('VIDEO STREAMING SLICE', `There are no sharing participants for call ${streamer.sessionId}`);
            } else {
                Log.notice('VIDEO STREAMING SLICE', `Kicking out sharing participants: ${participantsData.newSwto}`);

                streamer.voipStack.kickInvitedParticipants(participantsData.newSwto);
            }
        }

        streamer.hasNewParticipantsInvited = false;
        this.dispatch('setStreamer', streamer);
    };

    /**
     * @param {SWSIPVOIP2.Call.CEvent} sipEvent
     */
    onParticipantOnHold = (sipEvent) => {
        const streamer = this.getStreamerByLastUpdatedParticipant(sipEvent);

        // should never happen
        if (streamer === null) {
            return;
        }

        Log.debug('VIDEO STREAMING SLICE', `Participant on hold for session ${sipEvent.call.getSessionId()}`);

        streamer.live = false;
        this.dispatch('setStreamer', streamer);
    };

    /**
     * @param {SWSIPVOIP2.Call.CEvent} sipEvent
     */
    onParticipantResume = (sipEvent) => {
        const streamer = this.getStreamerByLastUpdatedParticipant(sipEvent);

        // should never happen
        if (streamer === null) {
            return;
        }

        Log.debug('VIDEO STREAMING SLICE', `Participant resume for session ${sipEvent.call.getSessionId()}`);

        streamer.live = true;
        this.dispatch('setStreamer', streamer);
    };

    /**
     * @param {SWSIPVOIP2.Call.CEvent} sipEvent
     */
    onParticipantLeft = (sipEvent) => {
        const streamer = this.getStreamerBySessionId(sipEvent.call.getSessionId());
        const participantMsisdn = sipEvent.call.getLastUpdatedParticipant();

        // should never happen
        if (streamer === null) {
            return;
        }

        if (streamer.msisdn === participantMsisdn) {
            Log.debug(
                'VIDEO STREAMING SLICE',
                `Streamer user ${participantMsisdn} has left the session ${sipEvent.call.getSessionId()}`,
            );

            streamer.live = false;
            streamer.streamingEndedBySource = true;
            this.dispatch('setStreamer', streamer);
        } else {
            Log.debug(
                'VIDEO STREAMING SLICE',
                `Participant ${participantMsisdn} has left the streaming ${sipEvent.call.getSessionId()}`,
            );

            // check if this was one of the shared participants
            const participantsData = streamer.voipStack.getLastInvitedParticipantsData();

            if (
                typeof participantsData.newSwto === 'undefined' ||
                participantsData.newSwto === null ||
                participantsData.newSwto.length === 0
            ) {
                Log.notice(
                    'VIDEO STREAMING SLICE',
                    `All sharing participants for call ${streamer.sessionId} left the call`,
                );
                streamer.hasNewParticipantsInvited = false;
                this.dispatch('setStreamer', streamer);
            }
        }
    };

    armTimeCounter = (streamerId) => {
        if (typeof timeCounters[streamerId] !== 'undefined') {
            clearInterval(timeCounters[streamerId]);
        }

        timeCounters[streamerId] = setInterval(() => {
            const state = this.getOwnState().streamers;

            // streamer gone
            if (typeof state[streamerId] === 'undefined') {
                clearInterval(timeCounters[streamerId]);
                delete timeCounters[streamerId];

                return;
            }

            const streamer = { ...state[streamerId] };

            if (!streamer.live) {
                return;
            }

            streamer.liveTime += 1;

            this.dispatch('setStreamer', streamer);
        }, 1000);
    };

    onSdkClientAuthenticated = () => {
        sdkClient.getCugSettings().then((cugSettings) => {
            this.dispatch('setSharingEnabled', cugSettings.ShareVideoStreaming);
        });
    };

    injectUserInStreamer;
}

// the handler
export const videoStreamingSliceHandler = VideoStreamingSliceHandler.getInstance();
export const selectSharingEnabled = (state) => state.videoStreamingSlice.sharingEnabled;
export const {
    connectToUserStreaming,
    connectToCameraStreaming,
    getStreamerByMsisdn,
    getStreamerBySessionId,
    removeStreamer,
    stopShare,
} = videoStreamingSliceHandler;
