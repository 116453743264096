import { addToasterToStore, removeToasterFromStore } from 'modules/shared/stores/toasters-slice-handler';

/**
 * Remove a toaster.
 * @param {ToasterOptions} toasterOptions
 */
export function removeToaster(toasterOptions) {
    removeToasterFromStore(toasterOptions);
}

/**
 * Show a toaster.
 * @param {ToasterOptions} toasterOptions
 */
export function addToaster(toasterOptions) {
    addToasterToStore(toasterOptions);
}
