import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import WorldInterface from 'utils/world-interface';
import App from 'app';
import LayoutManager from 'utils/layout-manager';
import MuiThemeCustomizer from 'utils/mui-theme-customizer';
import LocalEvent from 'utils/local-event';
import sdkClient from 'utils/sdk-client';
import store from 'modules/shared/stores/store';
import { injectEnvironmentLocales } from 'utils/translate';

// publish the interface in window's context
window.WCFUI = WorldInterface;

const rootId = 'wcf-ui-root';

window.addEventListener('load', () => {
    let root = document.getElementById(rootId);

    // make sure that we start clean
    if (root !== null) {
        root.innerHTML = '';
    } else {
        root = document.createElement('div');
        root.setAttribute('id', rootId);
        document.body.prepend(root);
    }

    const reactRoot = createRoot(document.getElementById(rootId));

    const renderApp = () => {
        const theme = MuiThemeCustomizer.getCustomizedTheme();

        injectEnvironmentLocales();

        reactRoot.render(
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <App />
                </ThemeProvider>
            </Provider>,
        );
    };

    LayoutManager.setupEnvironment().then(() => {
        renderApp();
        sdkClient.init();
    });

    // when the language has been changed entire app must re-render
    LocalEvent.setListener(LocalEvent.eventTypes.LAYOUT_MANAGER_LANGUAGE_UPDATED, () => {
        renderApp();
    });
});
