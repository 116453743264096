/**
 * This class is used to be extended by all other store classes which are consuming a voip stack client.
 * The class defines all listeners for the stack events; the listeners can be overwritten at store level.
 */
import Log from 'utils/log';
import Queue from 'utils/queue';
import sdkClient from 'utils/sdk-client';
import { contactsSliceHandler } from 'modules/contacts/stores/contacts-slice-handler';
import AbstractSliceHandler from './abstract-slice-handler';

export default class AbstractVoipStackClient extends AbstractSliceHandler {
    /**
     * Attempt to connect to the call.
     * @param {VoipStackOptions} options
     * @return Promise<SWSIPVOIP2.Call|null>
     */
    initializeVoipStack = (options) =>
        new Promise((resolve) => {
            // some sanity validation over the options
            if (typeof options.sessionId === 'undefined' && typeof options.swTo === 'undefined') {
                Log.error('VOIP STACK CLIENT', '"sessionId" and "swTo" cannot be both empty');

                resolve(null);

                return;
            }

            sdkClient
                .getCugSettings()
                .then((cugSettings) => {
                    sdkClient
                        .getVoipConfig()
                        .then((voipConfig) => {
                            let voipStack = null;

                            const queue = new Queue(() => {
                                resolve(voipStack);
                            });

                            voipConfig.VoipServerFqdns.split(',').forEach((serverFqdn) => {
                                // stack already created on another FQDN
                                if (voipStack !== null) {
                                    queue.advance();

                                    return;
                                }

                                const ua = new SWSIPVOIP2.UA({
                                    host: serverFqdn,
                                    port: 5063,
                                });

                                const getSubscriberToken = () => sdkClient.getVoipToken();

                                const stackOptions = {
                                    subscriberMsisdn: contactsSliceHandler.getLoggedInUser().msisdn,
                                    getSubscriberToken,
                                    sessionType: options.sessionType,
                                    sessionMedia: options.sessionMedia,
                                    synchronizeWithOtherDevices: cugSettings.SynchronizeWithOtherDevices,
                                    autoReconnect: true,
                                };

                                if (typeof options.swTo !== 'undefined') {
                                    stackOptions.swTo = options.swTo;
                                } else if (typeof options.sessionId !== 'undefined') {
                                    stackOptions.sessionId = options.sessionId;
                                } else {
                                    // never reach here; was treated at the beginning, before making the HTTP requests
                                }

                                let playOptions = {};

                                if (typeof options.rendererRemote !== 'undefined') {
                                    playOptions = { ...playOptions, rendererRemote: options.rendererRemote };
                                }

                                if (typeof options.renderLocal !== 'undefined') {
                                    playOptions = { ...playOptions, renderLocal: options.renderLocal };
                                }

                                if (Object.keys(playOptions).length > 0) {
                                    stackOptions.playOptions = playOptions;
                                }

                                voipStack = ua.newCall(stackOptions);

                                resolve(voipStack);
                            });
                        })
                        .catch((e) => {
                            Log.error('VOIP STACK CLIENT', 'There was an error getting the VoipConfig data');
                            console.log(e);

                            resolve(null);
                        });
                })
                .catch((e) => {
                    Log.error('VOIP STACK CLIENT', 'There was an error getting the CUG Settings');
                    console.log(e);

                    resolve(null);
                });
        });
}
