import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function SimpleModal({ dialogTitle, closeCallback, buttons, children }) {
    const [opened, setOpened] = React.useState(true);

    return (
        <Dialog open={opened}>
            {dialogTitle && (
                <DialogTitle>
                    <div className="wcf-ui-dialog-title">
                        <div className="modal-title">{dialogTitle}</div>
                        {closeCallback && (
                            <div
                                className="modal-close"
                                onClick={() => {
                                    setOpened(false);
                                    closeCallback();
                                }}
                            >
                                <span className="stw-cancel" />
                            </div>
                        )}
                    </div>
                </DialogTitle>
            )}
            <DialogContent>{children}</DialogContent>
            {buttons && <DialogActions>{buttons}</DialogActions>}
        </Dialog>
    );
}

export default SimpleModal;
