import { createSlice } from '@reduxjs/toolkit';
import AbstractSliceHandler from 'modules/shared/stores/abstract-slice-handler';
import Log from 'utils/log';

/**
 * @type {{portals: PortalOptions[]}}
 */
const initialState = {
    /**
     * The list of portals that must be rendered.
     */
    portals: {},
    /**
     * The id of the last portal added.
     */
    lastAddedPortalId: null,
};

class PortalsSliceHandler extends AbstractSliceHandler {
    static getInstance() {
        if (!PortalsSliceHandler.instance) {
            PortalsSliceHandler.instance = new PortalsSliceHandler();
        }

        return PortalsSliceHandler.instance;
    }

    constructor() {
        super('portalsSlice');

        this.slice = createSlice({
            name: this.sliceName,
            initialState,
            reducers: {
                /**
                 * Register a portal.
                 * @param {object} state
                 * @param {{payload: PortalOptions}} action
                 */
                addPortal(state, action) {
                    const newPortals = { ...state.portals };
                    // make sure that same component rendered is rendered only once for same reason
                    const id = `${action.payload.reason}-${action.payload.componentPath}`;
                    newPortals[id] = { id, ...action.payload };

                    state.portals = newPortals;
                    state.lastAddedPortalId = id;

                    Log.debug(
                        'PORTALS STORE',
                        `Registering portal for component ${action.payload.componentPath} with` +
                            ` reason ${action.payload.reason}`,
                    );
                },
                /**
                 * Remove a registered portal.
                 * @param {object} state
                 * @param {{payload: portalId}} action
                 */
                removePortal(state, action) {
                    // portal not registered
                    if (typeof state.portals[action.payload] === 'undefined') {
                        Log.warn(
                            'PORTALS STORE',
                            `The portal with id ${action.payload} is not registered so cannot be removed.`,
                        );

                        return;
                    }

                    const newPortals = { ...state.portals };
                    delete newPortals[action.payload];

                    state.portals = newPortals;
                },
            },
        });
    }
}

// the handler
export const portalsSliceHandler = PortalsSliceHandler.getInstance();
// the selectors to be used in useSelect
export const selectPortals = (state) => state.portalsSlice.portals;
export const selectLastAddedPortalId = (state) => state.portalsSlice.lastAddedPortalId;
// other named exports
export const { addPortal, removePortal } = portalsSliceHandler.slice.actions;
export const getLastAddedPortalId = () => portalsSliceHandler.store.getState().portalsSlice.lastAddedPortalId;
