import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import Portal from '@mui/material/Portal';
import { selectPortals } from 'modules/shared/stores/portals-slice-handler';
import { lazyImportComponent } from 'utils/functions';

/**
 * Render a portal using the options
 * @param {PortalOptions} portal
 */
const renderPortal = (portal) => {
    return (
        <Portal key={portal.id} container={portal.container}>
            {lazyImportComponent(portal)}
        </Portal>
    );
};

function PortalsRenderer() {
    const portals = useSelector(selectPortals);

    return <>{Object.keys(portals).map((portalId) => renderPortal(portals[portalId]))}</>;
}

export default PortalsRenderer;
