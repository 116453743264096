import Queue from 'utils/queue';
import Log from 'utils/log';
import { addToaster } from 'utils/toasters-manager';
import WsErrorCodes from 'constants/ws-error-codes';
import t from 'utils/translate';

/**
 * This class must be extended by all slice handlers.
 */
export default class AbstractSliceHandler {
    /**
     * The slice in the store.
     */
    slice;

    constructor(sliceName) {
        this.sliceName = sliceName;
    }

    /**
     * This is required in order to prevent circular dependency: store imports the reducers and the reducers import the store
     * @param {Store} store
     */
    setStore(store) {
        this.store = store;
    }

    /**
     * Get the reducer to be injected in the store.
     * @return {*}
     */
    getReducer() {
        return this.slice.reducer;
    }

    /**
     * Address directly the slice state from the global store.
     * @return {*}
     */
    getOwnState() {
        return this.store.getState()[this.sliceName];
    }

    action(actionName, ...actionParams) {
        return this.slice.actions[actionName](...actionParams);
    }

    dispatch(actionName, ...actionParams) {
        this.store.dispatch(this.action(actionName, ...actionParams));
    }

    /**
     * Dispatch multiple actions sequentially.
     * @param {Function} actions
     */
    dispatchMultiple(...actions) {
        if (actions.length === 1) {
            this.store.dispatch(actions[0]);
            return;
        }

        const queue = new Queue(() => {
            // nothing to do
        });

        actions.forEach((action) => {
            this.store.dispatch(action);

            setTimeout(() => {
                queue.advance();
            });
        });

        queue.run();
    }

    /**
     * Some SDk error coder requires special labels in toasters.
     * The labels depend on the codes returned by web service.
     * @param {string} methodName
     * @param {SdkError} sdkError
     */
    treatSdkError(methodName, sdkError) {
        Log.error(`SLICE HANDLER(${this.sliceName})`, `The method ${methodName} has received SDK error: `);
        // do not remove this line; it is part of debugging process
        console.log(sdkError);

        let toasterMessage = null;
        let toasterType = 'error';

        // terrible error response received from sdk and service-api
        if (
            sdkError.err &&
            sdkError.err.response &&
            sdkError.err.response.data &&
            sdkError.err.response.data.error &&
            sdkError.err.response.data.error.originalError
        ) {
            switch (sdkError.err.response.data.error.originalError.faultCode) {
                case WsErrorCodes.MEETING_ROOMS_MAX_NUMBER_OF_ROOMS_REACHED:
                    toasterMessage = t('meeting_rooms_error_max_number_reached');
                    toasterType = 'warning';
                    break;

                case WsErrorCodes.MEETING_ROOMS_DUPLICATED_LABEL:
                    toasterMessage = t('meeting_rooms_error_duplicated_label');
                    toasterType = 'warning';
                    break;

                case WsErrorCodes.USER_GROUPS_LABEL_IN_USE:
                    toasterMessage = t('manage_user_groups_error_label_in_use');
                    break;

                case WsErrorCodes.USER_GROUPS_MAX_NUMBER_OF_GROUPS_REACHED:
                    toasterMessage = t('manage_user_groups_error_max_number_reached');
                    break;

                case WsErrorCodes.USER_GROUPS_GROUP_OF_GROUPS_ERROR:
                    toasterMessage = t('manage_user_groups_error_group_of_groups');
                    break;

                case WsErrorCodes.USER_GROUPS_MAXIMUM_LEVEL_ERROR:
                    toasterMessage = t('manage_user_groups_error_group_of_groups');
                    break;

                default:
                    toasterMessage = t('global_unexpected_error');
                    break;
            }
        }

        if (toasterMessage !== null) {
            addToaster({
                type: toasterType,
                message: toasterMessage,
            });
        }
    }
}
