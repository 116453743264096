const TabTypes = {
    /**
     * Users tab
     */
    USERS_TAB: 0,
    /**
     * Groups tab
     */
    GROUPS_TAB: 1,
};
const TransferListAreas = {
    /**
     * The left part of the transfer list
     */
    LEFT_LIST: 'LEFT_LIST',
    /**
     * The right part of the transfer list
     */
    RIGHT_LIST: 'RIGHT_LIST',
};
const TransferListTypes = {
    /**
     * Transfer list section for adding users.
     */
    LIST_SECTION_USERS: 'LIST_SECTION_USERS',
    /**
     * Transfer list section for adding admins.
     */
    LIST_SECTION_ADMINS: 'LIST_SECTION_ADMINS',
};
export { TransferListTypes, TransferListAreas, TabTypes };
