/**
 * This class permits sequential execution of chained callbacks.
 * In order to work each callback attached must call queue.advance.
 */
class Queue {
    /**
     * The callback to be executed when the queue has done loading.
     * @param {function} endCallback
     */
    constructor(endCallback) {
        this.endCallback = endCallback;
        this.callbackStack = [];
        this.queued = 0;
        this.endCallbackCalled = false;
        this.aborted = false;
        this.paused = false;
    }

    /**
     * Set a callback to be executed.
     * @param {function} callback
     * @returns {Queue}
     */
    add(callback) {
        this.callbackStack.push(callback);

        return this;
    }

    /**
     * This method is making the que to advance from one callback to another.
     * @returns {undefined}
     */
    advance() {
        if (this.aborted) {
            return true;
        }

        if (this.paused) {
            return true;
        }

        if (this.queued > 1) {
            this.queued--;

            this.callbackStack[this.callbackStack.length - this.queued]();
        } else if (!this.endCallbackCalled) {
            this.endCallbackCalled = true;

            this.endCallback();
        } else {
            // nothing to do; invalid advance call
        }

        return false;
    }

    /**
     * Triggers the running process.
     * @returns {undefined}
     */
    run() {
        if (this.aborted) {
            return;
        }

        if (this.callbackStack.length === 0) {
            this.end();

            return;
        }

        this.queued = this.callbackStack.length;
        this.callbackStack[0]();
    }

    /**
     * Abort the running queue.
     * No advance callbacks nor end callback will be executed anymore.
     * @returns {undefined}
     */
    abort() {
        this.aborted = true;
    }

    /**
     * Boolean flag which informs if the running queue was aborted before.
     * @returns {boolean}
     */
    isAborted() {
        return this.aborted;
    }

    /**
     * Boolean flag which informs if the running queue was ended.
     * @returns {boolean}
     */
    isEnded() {
        return this.endCallbackCalled;
    }

    /**
     * Skip all other steps and executed the end callback directly.
     * @returns {undefined}
     */
    end() {
        if (!this.endCallbackCalled) {
            this.endCallbackCalled = true;

            this.endCallback();
        }
    }

    /**
     * Pause the execution of the queue.
     * A paused queue can be:
     * - resumed
     * - ended
     * A paused queue will not advance.
     * @returns {undefined}
     */
    pause() {
        this.paused = true;
    }

    /**
     * Resume a paused queue.
     * @returns {undefined}
     */
    resume() {
        if (!this.paused) {
            return;
        }

        this.paused = false;

        this.advance();
    }

    /**
     * Check if the queue is paused.
     * @returns {undefined}
     */
    isPaused() {
        return this.paused;
    }
}
export default Queue;
