import * as React from 'react';
import MuiAlert from '@mui/material/Alert';
import { selectToasters } from '../stores/toasters-slice-handler';
import { useSelector } from 'react-redux';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * Creates a toaster.
 * @param {string} type The same types as in WebChat: "notice" | "warning" | "error"
 * @param {string} message
 * @param {number} duration DEfault duration is 5 seconds.
 * @return {JSX.Element}
 * @constructor
 */
function Toasters() {
    const toasters = useSelector(selectToasters);

    /**
     * Render a toaster element.
     * @param toasterId
     * @param {ToasterOptions} toasterOptions
     * @return {JSX.Element}
     */
    const renderToaster = (toasterId, toasterOptions) => {
        const duration = toasterOptions.duration ?? 5;

        let severity;

        switch (toasterOptions.type) {
            case 'notice':
            default:
                severity = 'info';
                break;

            case 'warning':
                severity = 'warning';
                break;

            case 'error':
                severity = 'error';
                break;
        }

        return (
            <div className="wcf-ui-toaster" key={toasterId}>
                <div className={'flex-space'}></div>
                <Alert
                    icon={false}
                    severity={severity}
                    action={
                        <>
                            <div className={'toaster-close-button'}>
                                <span
                                    className={'stw-cancel-close'}
                                    onClick={() => {
                                        toasterOptions.onClose();
                                    }}
                                ></span>
                            </div>
                        </>
                    }
                >
                    {toasterOptions.message}
                </Alert>
            </div>
        );
    };

    return (
        <div className={'wcf-ui-toaster-manager'}>
            {Object.keys(toasters).map((toasterId) => renderToaster(toasterId, toasters[toasterId]))}
        </div>
    );
}

export default Toasters;
