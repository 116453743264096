import store from 'modules/shared/stores/store';
import { addPortal, getLastAddedPortalId, removePortal } from 'modules/shared/stores/portals-slice-handler';
import {
    openFloatingWindow,
    closeFloatingWindow,
    getLastAddedFloatingWindowId,
} from 'modules/shared/stores/floating-windows-slice-handler';
import LayoutManager from './layout-manager';
import LocalEvent from './local-event';
import Log from './log';

/**
 * This class is a utility that allows the external world (such as WebChat) to interact with UI app.
 */
class WorldInterface {
    /**
     * Singleton instance.
     * @return {WorldInterface}
     */
    static getInstance() {
        if (typeof WorldInterface.instance !== 'undefined') {
            return WorldInterface.instance;
        }

        WorldInterface.instance = new WorldInterface();

        return WorldInterface.instance;
    }

    /**
     * This function allows external world to relate with the local events triggered inside the UI app.
     * @param {string} eventType
     * @param {function} callback
     * @param {boolean} volatile If the listener should be discarded after its execution.
     */
    setEventListener(eventType, callback, volatile = false) {
        try {
            LocalEvent.setListener(eventType, callback, volatile);
        } catch (e) {
            // fallback silently without breaking the client
            Log.error('WORLD INTERFACE', e.message);
        }
    }

    /**
     * This function allows setting a SINGLE listener of the given callback.
     * @param {string} eventType
     * @param {function} callback
     */
    setUniqueEventListener(eventType, callback) {
        try {
            LocalEvent.setListener(eventType, callback, false, true);
        } catch (e) {
            // fallback silently without breaking the client
            Log.error('WORLD INTERFACE', e.message);
        }
    }

    getLayoutManager() {
        return LayoutManager;
    }

    /**
     * Print the state of the app from store.
     * @return object
     */
    debugState() {
        return store.getState();
    }

    /**
     * LAZY Render a Rect component inside the given element.
     * @param {PortalOptions} options
     * @return Promise<string> The id of the added portal.
     */
    renderComponent(options) {
        return new Promise((resolve) => {
            store.dispatch(addPortal(options));

            setTimeout(() => {
                resolve(getLastAddedPortalId());
            });
        });
    }

    /**
     * Remove from DOM a component which has been rendered with renderComponent.
     * @param componentUuid
     */
    removeComponent(componentUuid) {
        store.dispatch(removePortal(componentUuid));
    }

    /**
     * Creates a floating window which renderers a component.
     * @param {FloatingWindowOptions} options
     * @return Promise<string> The id of the opened floating window.
     */
    openFloatingWindow(options) {
        return new Promise((resolve) => {
            openFloatingWindow(options);

            setTimeout(() => {
                resolve(getLastAddedFloatingWindowId());
            });
        });
    }

    /**
     * Close a floating window.
     * @param {string} floatingWindowId The id of a floating window which has been opened with openFloatingWindow.
     */
    closeFloatingWindow(floatingWindowId) {
        closeFloatingWindow(floatingWindowId);
    }

    /**
     * Open the window with UI from the WebChat.
     * This is supposed to be called only when the WCFUI is embedded under webchat page.
     */
    openFromWebChat() {
        let url = '';

        Object.keys(wcfUiCfg).forEach((cfgKey) => {
            // on its own the interface needs empty opener property
            if (cfgKey === 'opener') {
                return;
            }

            const val = typeof wcfUiCfg[cfgKey] === 'object' ? JSON.stringify(wcfUiCfg[cfgKey]) : wcfUiCfg[cfgKey];

            url += `&${cfgKey}=${val}`;
        });

        // remove the first &
        url = `${WebchatURI}/ui/?${url.substring(1)}`;

        window.open(url, '_blank');
    }
}
export default WorldInterface.getInstance();
