import Queue from './queue';
import LocalEvent from './local-event';
import Log from './log';
import { fetchJson } from './functions';

/**
 * This class provides top level functions that allows interacting with the layout.
 */
class LayoutManager {
    static getInstance() {
        if (typeof LayoutManager.instance !== 'undefined') {
            return LayoutManager.instance;
        }

        LayoutManager.instance = new LayoutManager();

        return LayoutManager.instance;
    }

    constructor() {
        if (typeof wcfUiCfg === 'undefined') {
            throw 'The configuration variable "wcfUiCfg" must be defined prior embedding ui application.';
        }

        // important for later references
        this.defaultSkin = 'totr';
        this.direction = wcfUiCfg.direction;
        this.language = wcfUiCfg.language;
        this.cssId = null;
        this.languageId = null;
        this.locales = {};
    }

    setupEnvironment() {
        return new Promise((resolve) => {
            const startTime = new Date().getTime();
            const loadQueue = new Queue(() => {
                Log.debug('LAYOUT MANAGER', `Scripts loaded in ${new Date().getTime() - startTime} ms`);

                // cache the CSS that allows accessing the color CSS variables
                this.computedCss = window.getComputedStyle(document.querySelector(':root'));

                LocalEvent.trigger(LocalEvent.eventTypes.LAYOUT_MANAGER_INITIAL_SCRIPTS_LOADED);

                resolve();
            });

            loadQueue.add(() => {
                this.injectProductContent().then(() => loadQueue.advance());
            });
            loadQueue.add(() => {
                this.loadBrandingSummary().then(() => loadQueue.advance());
            });
            loadQueue.add(() => {
                this.injectBrandingContent().then(() => loadQueue.advance());
            });
            loadQueue.add(() => {
                this.injectLocales(this.language).then(() => loadQueue.advance());
            });

            loadQueue.run();
        });
    }

    /**
     * Toggle the direction.
     * @return {Promise<unknown>}
     */
    toggleDirection() {
        const direction = this.direction === 'rtl' ? 'ltr' : 'rtl';
        return this.changeDirection(direction);
    }

    changeDirection(direction) {
        if (this.direction === direction) {
            return true;
        }

        const startTime = new Date().getTime();

        LocalEvent.trigger(LocalEvent.eventTypes.LAYOUT_MANAGER_UPDATE_IN_PROGRESS);

        return new Promise((resolve) => {
            this.direction = direction;

            this.injectDirectionStyle(this.direction).then(() => {
                Log.notice(
                    'LAYOUT MANAGER',
                    'The CSS direction has been changed to' +
                        ` ${this.direction} in ${new Date().getTime() - startTime} ms`,
                );

                LocalEvent.trigger(LocalEvent.eventTypes.LAYOUT_MANAGER_CSS_DIRECTION_UPDATED);

                resolve();
            });
        });
    }

    changeLanguage(language) {
        const startTime = new Date().getTime();

        LocalEvent.trigger(LocalEvent.eventTypes.LAYOUT_MANAGER_UPDATE_IN_PROGRESS);

        return new Promise((resolve, reject) => {
            this.language = language;

            this.injectLocales(this.language)
                .then(() => {
                    Log.notice(
                        'LAYOUT MANAGER',
                        'The language has been changed to' +
                            ` ${this.language} in ${new Date().getTime() - startTime} ms`,
                    );

                    LocalEvent.trigger(LocalEvent.eventTypes.LAYOUT_MANAGER_LANGUAGE_UPDATED);

                    resolve();
                })
                .catch(() => {
                    if (typeof reject === 'function') {
                        reject();
                    }
                });
        });
    }

    getLanguage() {
        return this.language;
    }

    getDirection() {
        return this.direction;
    }

    injectDirectionStyle(direction) {
        const existingLink = document.getElementById(this.cssId);
        const newDirection = direction === 'rtl' ? 'wcf-ui-unified-rtl.css' : 'wcf-ui-unified.css';
        const cssHref = `/ui/css/${newDirection}`;

        this.cssId = `css-index-${new Date().getTime()}`;

        return new Promise((resolve) => {
            this.appendCss(this.cssId, cssHref).then((link) => {
                link.addEventListener('load', () => {
                    if (existingLink !== null) {
                        existingLink.remove();
                    }

                    document.getElementsByTagName('html')[0].setAttribute('dir', direction);

                    resolve();
                });
            });
        });
    }

    injectLocales(language) {
        language = language.replace('_', '-');

        return new Promise((resolve, reject) => {
            const languageQueue = new Queue(() => {
                resolve();
            });

            if (
                typeof this.brandingSummary.locales[language] !== 'undefined' &&
                this.brandingSummary.locales[language] === true
            ) {
                languageQueue.add(() => {
                    Log.debug(
                        'LAYOUT MANAGER',
                        `The language ${language} has inheritance from default skin ${this.defaultSkin}. Will load default labels ...`,
                    );
                    fetchJson(`${this.brandingBaseUrl(this.defaultSkin)}/locales/${language}__${this.defaultSkin}.json`)
                        .then((defaultLocales) => {
                            this.locales = defaultLocales;
                            languageQueue.advance();
                        })
                        .catch(() => {
                            Log.error('LAYOUT MANAGER', `Error while loading the default labels.`);

                            reject();
                        });
                });
            }

            languageQueue.add(() => {
                fetchJson(`${this.brandingBaseUrl(wcfUiCfg.skin)}/locales/${language}__${wcfUiCfg.skin}.json`).then(
                    (brandLocales) => {
                        this.locales = { ...this.locales, ...brandLocales };
                        languageQueue.advance();
                    },
                );
            });

            languageQueue.run();
        });
    }

    appendCss(id, cssHref) {
        return new Promise((resolve) => {
            const head = document.getElementsByTagName('head')[0];
            const link = document.createElement('link');
            link.id = id;
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = cssHref;
            link.media = 'all';
            head.appendChild(link);

            resolve(link);
        });
    }

    /**
     * Extract the HEXA value of the color from a CSS variable
     * @param {string} cssVarName The name of the CSS variable.
     * @param {number} alpha Optionally may provide the alpha: 00 - 11
     * @return {string}
     */
    getColorFromCssVar(cssVarName, alpha = null) {
        let color = this.computedCss.getPropertyValue(cssVarName).trim();

        if (alpha !== null) {
            // transform in rgb
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);

            color = `rgba(${r},${g},${b},${alpha})`;
        }

        return color;
    }

    // REBRANDING AREA

    /**
     * Inject the content which is not subject of branding.
     * - the unified CSS
     * @return Promise
     */
    injectProductContent() {
        return new Promise((resolve) => {
            Promise.all([this.injectDirectionStyle(this.direction)]).then(() => {
                resolve();
            });
        });
    }

    /**
     * Inject the content which is subject of branding:
     * - CSS
     * - assets variables
     * - glyph font if any
     * @return Promise
     */
    injectBrandingContent() {
        return new Promise((resolve) => {
            const brandingQueue = new Queue(() => {
                resolve();
            });

            // Glyph loader
            brandingQueue.add(() => {
                let glyphFontLoaderUrl = `${this.brandingBaseUrl(this.defaultSkin)}/css/${
                    this.defaultSkin
                }-glyph-font-loader.css`;

                if (this.brandingSummary.glyphFontCustomisation === true) {
                    Log.debug('LAYOUT MANAGER', `The skin ${wcfUiCfg.skin} has its own Glyph font. Will use it.`);
                    glyphFontLoaderUrl = `${this.brandingBaseUrl(wcfUiCfg.skin)}/css/${
                        wcfUiCfg.skin
                    }-glyph-font-loader.css`;
                } else {
                    Log.notice(
                        'LAYOUT MANAGER',
                        `The skin ${wcfUiCfg.skin} has no Glyph font. Will use the one from totr.`,
                    );
                }

                Log.debug('LAYOUT MANAGER', `Attempting to load the glyph font from url: ${glyphFontLoaderUrl}`);

                this.appendCss('glyph-font-loader', glyphFontLoaderUrl).then(() => {
                    brandingQueue.advance();
                });
            });

            // SKIN brand file
            brandingQueue.add(() => {
                const brandVarsUrl = `${this.brandingBaseUrl(wcfUiCfg.skin)}/css/${wcfUiCfg.skin}.css`;

                Log.debug('LAYOUT MANAGER', `Attempting to load the brand vars from url: ${brandVarsUrl}`);

                this.appendCss('skin-vars', brandVarsUrl).then(() => {
                    brandingQueue.advance();
                });
            });

            // ASSETS URLS
            brandingQueue.add(() => {
                const assetsVarsUrl = `${this.brandingBaseUrl(wcfUiCfg.skin)}/css/${wcfUiCfg.skin}-assets-urls.css`;

                Log.debug('LAYOUT MANAGER', `Attempting to load the assets vars from url: ${assetsVarsUrl}`);

                this.appendCss('assets-vars', assetsVarsUrl).then(() => {
                    brandingQueue.advance();
                });
            });

            brandingQueue.run();
        });
    }

    /**
     * Make a request to the server and load the branding summary that may have been generated.
     */
    loadBrandingSummary() {
        return new Promise((resolve, reject) => {
            if (typeof this.brandingSummary !== 'undefined') {
                resolve(this.brandingSummary);

                return;
            }

            const brandingUrl = `${this.brandingBaseUrl(wcfUiCfg.skin)}/${wcfUiCfg.skin}-branding-summary.json`;

            Log.debug('LAYOUT MANAGER', `Attempting to load branding summary from url: ${brandingUrl}`);

            fetchJson(brandingUrl)
                .then((json) => {
                    this.brandingSummary = json;
                    resolve(json);
                })
                .catch((error) => {
                    // Catch errors
                    Log.error('LAYOUT MANAGER', 'There was an error while loading the branding summary: ');
                    console.log(error);

                    reject();
                });
        });
    }

    brandingBaseUrl = (skin) =>
        `${window.location.protocol}//${window.location.host}/branding/skins/${skin}/webchat-frontend`;

    getLocales = () => this.locales;
}
export default LayoutManager.getInstance();
