import LocalEventTypes from 'constants/local-event-types';
import Log from './log';

class LocalEvent {
    static getInstance() {
        if (typeof LocalEvent.instance !== 'undefined') {
            return LocalEvent.instance;
        }

        LocalEvent.instance = new LocalEvent();

        return LocalEvent.instance;
    }

    constructor() {
        this.history = {};
        this.eventTypes = LocalEventTypes;
        this.uniqueCallbacks = {};
    }

    /**
     * Trigger a local event.
     * @param {string} eventType The standardized type of the event.
     * @param {object} eventParams The parameters that must be shipped with the event (if any).
     * @see constants/local-events for the available standardized names
     * @throws error if the name is not declared in the constants file
     * @returns {undefined}
     */
    trigger(eventType, ...eventParams) {
        if (typeof LocalEventTypes[eventType] === 'undefined') {
            throw new Error(
                `Cannot trigger the event ${eventType}` +
                    ' because was not declared in constants file (constants/local-event-types) ',
            );
        }

        this.history[eventType] = 1;

        // there are events that may occur very often, and that will log too much
        const noLogEvents = [];

        if (noLogEvents.indexOf(eventType) < 0) {
            Log.debug('LOCAL EVENT', `Triggering local event ${eventType}.`);
        }

        // cannot use Customevent class because it seems is lossing its "detail" property
        // will use Event instead
        const evt = new Event(eventType);
        evt.detail = eventParams;

        document.querySelector('html').dispatchEvent(evt);
    }

    /**
     * Set a listener for certain local event.
     * @param {string} eventType The standardized type of the event.
     * @param {function} callback The callback to be executed when the event gets triggered.
     * @param {boolean} volatile If the callback should be discarded after its execution or not. Default is false.
     * @param {boolean} unique If will allow or not setting same callback for same event more than once.
     * @see constants/local-events for the available standardized names
     * @throws error if the name is not declared in the constants file
     * @returns {undefined}
     */
    setListener(eventType, callback, volatile = false, unique = false) {
        if (typeof LocalEventTypes[eventType] === 'undefined') {
            throw new Error(
                `Cannot set a listener for the event "${eventType}"
                because was not declared in constants file (constants/local-event-types) `,
            );
        }

        if (unique === true) {
            if (typeof this.uniqueCallbacks[eventType] === 'undefined') {
                this.uniqueCallbacks[eventType] = [];
            }

            const callbackStr = callback.toString();

            // callback already registered
            if (this.uniqueCallbacks[eventType].indexOf(callbackStr) >= 0) {
                return;
            }

            this.uniqueCallbacks[eventType].push(callbackStr);
        }

        document.querySelector('html').addEventListener(eventType, callback, {
            once: volatile,
        });
    }

    /**
     * Check if certain events was triggered at least once.
     * @param {string} eventType The standardized type of the event.
     * @return {boolean}
     * @see constants/local-events for the available standardized names
     * @returns {boolean}
     */
    wasTriggered(eventType) {
        return typeof this.history[eventType] !== 'undefined';
    }
}
export default LocalEvent.getInstance();
