import React from 'react';
import PortalsRenderer from 'modules/shared/components/portals-renderer';
import FloatingWindowsRenderer from 'modules/shared/components/floating-windows-renderer';
import Toasters from 'modules/shared/components/toasters';
import CacheClearWarning from 'modules/shared/components/cache-clear-warning';

function App() {
    return (
        <>
            <CacheClearWarning />
            <PortalsRenderer />
            <FloatingWindowsRenderer />
            <Toasters />
        </>
    );
}

export default App;
