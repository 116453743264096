/**
 * Link the WebService error codes with meaningful identifiers.
 */
const WsErrorCodes = {
    MEETING_ROOMS_MAX_NUMBER_OF_ROOMS_REACHED: 100020207,
    MEETING_ROOMS_DUPLICATED_LABEL: 100020204,
    USER_GROUPS_MAX_NUMBER_OF_GROUPS_REACHED: 10032047,
    USER_GROUPS_LABEL_IN_USE: 20036001,
    USER_GROUPS_GROUP_OF_GROUPS_ERROR: 10006038,
    USER_GROUPS_MAXIMUM_LEVEL_ERROR: 20076005,
};
export default WsErrorCodes;
