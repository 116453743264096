import { createSlice } from '@reduxjs/toolkit';

import AbstractSliceHandler from 'modules/shared/stores/abstract-slice-handler';
import LocalEvent from 'utils/local-event';
import Log from 'utils/log';
import t from 'utils/translate';

/**
 * @type {{settings: {shortcuts: KeyboardShortcut[]
 * }}}}
 */
const initialState = {
    dataIsLoading: true,
    dataIsUpdating: false,
    collapsedSections: {},
    shortcutEditorData: {
        editedShortcut: null,
        deletedShortcut: null,
        canUseRecordedKey: false,
    },
    recordedShortcut: null,
    settings: {
        keyboardShortcuts: [
            {
                type: 'CALL_ANSWER',
                enabled: true,
                ctrlKey: true,
                shiftKey: true,
                code: 'PageUp',
            },
            {
                type: 'CALL_REJECT',
                enabled: true,
                ctrlKey: true,
                shiftKey: true,
                code: 'PageDown',
            },
            {
                type: 'FLOOR_CONTROL',
                enabled: true,
                ctrlKey: true,
                code: 'Space',
            },
            {
                type: 'CHANNEL_MOVE_UP',
                enabled: true,
                ctrlKey: true,
                code: 'ArrowUp',
            },
            {
                type: 'CHANNEL_MOVE_DOWN',
                enabled: true,
                ctrlKey: true,
                code: 'ArrowDown',
            },
        ],
    },
};

const shortcutProps = ['altKey', 'ctrlKey', 'metaKey', 'shiftKey', 'code'];

class SettingsSliceHandler extends AbstractSliceHandler {
    static getInstance() {
        if (!SettingsSliceHandler.instance) {
            SettingsSliceHandler.instance = new SettingsSliceHandler();
        }

        return SettingsSliceHandler.instance;
    }

    constructor() {
        super('settingsSlice');

        this.keyPressDebouncer = null;

        LocalEvent.setListener(LocalEvent.eventTypes.SDK_CLIENT_AUTHENTICATED, () => {
            this.onSdkClientAuthenticated();
        });

        // let the slice set up, then arm the key events interceptor
        setTimeout(() => {
            this.armShortcutRecorder();
        });

        this.slice = createSlice({
            name: this.sliceName,
            initialState,
            reducers: {
                toggleLoading(state, action) {
                    state.dataIsLoading = action.payload;
                },
                toggleDataUpdating(state, action) {
                    state.dataIsUpdating = action.payload;
                },
                setEditedShortcut(state, action) {
                    state.shortcutEditorData.editedShortcut = action.payload;

                    if (
                        state.shortcutEditorData.editedShortcut !== null &&
                        state.shortcutEditorData.editedShortcut.enabled
                    ) {
                        state.shortcutEditorData.canUseRecordedKey = true;
                        state.recordedShortcut = { ...action.payload };
                    } else {
                        state.shortcutEditorData.canUseRecordedKey = false;
                        state.recordedShortcut = null;
                    }
                },
                setDeletedShortcut(state, action) {
                    state.shortcutEditorData.deletedShortcut = action.payload;
                },
                persistDeletedShortcut(state, action) {
                    state.dataIsUpdating = false;

                    if (action.payload.result) {
                        state.settings.keyboardShortcuts[action.payload.deletedIndex].enabled = false;
                    }

                    LocalEvent.trigger(LocalEvent.eventTypes.SHORTCUT_KEY_SAVED, action.payload.savedShortcut);
                },
                persistSavedShortcut(state, action) {
                    state.dataIsUpdating = false;
                    state.shortcutEditorData.editedShortcut = null;

                    if (action.payload.result) {
                        state.settings.keyboardShortcuts[action.payload.savedIndex] = action.payload.savedShortcut;
                    }

                    LocalEvent.trigger(LocalEvent.eventTypes.SHORTCUT_KEY_SAVED, action.payload.savedShortcut);
                },
                toggleSectionCollapse(state, action) {
                    if (typeof state.collapsedSections[action.payload] === 'undefined') {
                        state.collapsedSections[action.payload] = true;
                    } else {
                        delete state.collapsedSections[action.payload];
                    }
                },
                setKeyboardShortcuts(state, action) {
                    state.settings.keyboardShortcuts = action.payload;
                },
                setRecordedShortcut(state, action) {
                    state.recordedShortcut = action.payload;

                    if (state.shortcutEditorData.editedShortcut === null) {
                        state.shortcutEditorData.canUseRecordedKey = false;
                    } else {
                        const existingShortcuts = state.settings.keyboardShortcuts.filter(
                            (shortcut) =>
                                state.recordedShortcut !== null &&
                                shortcut.enabled &&
                                SettingsSliceHandler.getInstance().shortcutsMatch(
                                    shortcut,
                                    state.recordedShortcut,
                                    null,
                                ) &&
                                state.shortcutEditorData.editedShortcut.type !== shortcut.type,
                        );

                        state.shortcutEditorData.canUseRecordedKey = existingShortcuts.length === 0;
                    }
                },
            },
        });
    }

    onSdkClientAuthenticated() {
        this.dispatch('toggleLoading', true);

        webchatSDK.STWAPI.getUserUISettings().then(
            (settings) => {
                if (typeof settings.data.results.keyboardShortcuts !== 'undefined') {
                    this.dispatch('setKeyboardShortcuts', settings.data.results.keyboardShortcuts);
                } else {
                    Log.notice(
                        'SETTINGS STORE',
                        'The key "keyboardShortcuts" was not found in settings payload. Will use default ones.',
                    );
                }

                this.dispatch('toggleLoading', false);
            },
            (err) => {
                Log.error(
                    'SETTINGS STORE',
                    `There was an error while loading user ui settings: ${JSON.stringify(err)}`,
                );

                this.dispatch('toggleLoading', false);
            },
        );
    }

    armShortcutRecorder() {
        window.addEventListener('keydown', (e) => {
            if (this.keyPressDebouncer !== null) {
                clearTimeout(this.keyPressDebouncer);
            }

            this.keyPressDebouncer = setTimeout(() => {
                const recordedShortcut = {};

                shortcutProps.forEach((prop) => {
                    if (e[prop]) {
                        recordedShortcut[prop] = e[prop];
                    }
                });

                this.dispatch('setRecordedShortcut', recordedShortcut);

                // check if the given key press match any shortcut
                let matchedShortcut = null;
                this.getOwnState().settings.keyboardShortcuts.forEach((shortcut) => {
                    // react on first matching shortcut
                    if (matchedShortcut !== null) {
                        return;
                    }

                    if (!shortcut.enabled) {
                        return;
                    }

                    if (!this.shortcutsMatch(shortcut, recordedShortcut, e)) {
                        return;
                    }

                    matchedShortcut = shortcut;
                });

                if (matchedShortcut !== null) {
                    Log.notice('SETTINGS STORE', `The shortcut ${matchedShortcut.type} has been triggered.`);

                    LocalEvent.trigger(LocalEvent.eventTypes.SHORTCUT_KEY_PRESSED, matchedShortcut);
                }

                this.keyPressDebouncer = null;
            }, 200);
        });
    }

    shortcutsMatch(shortcut, reference, keyboardEvent = null) {
        let result = true;

        shortcutProps.forEach((prop) => {
            if (!result) {
                return;
            }

            if (typeof shortcut[prop] === 'undefined') {
                // the property which not exists in existing shortcut is assimilated as FALSE
                // which does not match with the keyboard event
                if (prop !== 'code') {
                    if (
                        (keyboardEvent !== null && keyboardEvent[prop] === true) ||
                        (typeof reference[prop] !== 'undefined' && reference[prop] === true)
                    ) {
                        result = false;
                    }
                }

                return;
            }

            result = shortcut[prop] === reference[prop];
        });

        return result;
    }

    // EXPORTABLE INTERFACE defined with arrow functions

    deleteShortcut = (shortcutToDelete) => {
        this.dispatch('toggleDataUpdating', true);

        const payload = [];
        let deletedIndex;

        this.getOwnState().settings.keyboardShortcuts.forEach((shortcut, index) => {
            const clone = { ...shortcut };

            if (clone.type === shortcutToDelete.type) {
                deletedIndex = index;
                clone.enabled = false;
            }

            payload.push(clone);
        });

        webchatSDK.STWAPI.updateUserUISettings('keyboardShortcuts', payload).then(
            () => {
                this.dispatch('persistDeletedShortcut', {
                    result: true,
                    deletedIndex,
                });
            },
            (err) => {
                Log.error(
                    'SETTINGS STORE',
                    `There was an error while saving keyboard shortcuts: ${JSON.stringify(err)}.`,
                );

                this.dispatch('persistDeletedShortcut', { result: false });
            },
        );
    };

    /**
     * Save the current recorded key in the edited shortcut.
     */
    saveEditedShortcut = () => {
        this.dispatch('toggleDataUpdating', true);

        const payload = [];
        let savedIndex;
        let savedShortcut;

        const state = this.getOwnState();

        state.settings.keyboardShortcuts.forEach((shortcut, index) => {
            let clone = { ...shortcut };

            if (clone.type === state.shortcutEditorData.editedShortcut.type) {
                savedIndex = index;
                clone = {
                    ...state.recordedShortcut,
                    ...{
                        enabled: true,
                        type: state.shortcutEditorData.editedShortcut.type,
                    },
                };
                savedShortcut = clone;
            }

            payload.push(clone);
        });

        webchatSDK.STWAPI.updateUserUISettings('keyboardShortcuts', payload).then(
            () => {
                this.dispatch('persistSavedShortcut', {
                    result: true,
                    savedIndex,
                    savedShortcut,
                });
            },
            (err) => {
                Log.error(
                    'SETTINGS STORE',
                    `There was an error while saving keyboard shortcuts: ${JSON.stringify(err)}.`,
                );

                this.dispatch('persistSavedShortcut', { result: false });
            },
        );
    };

    /**
     * Get the localized text associated with a keyboard shortcut.
     * @param {KeyboardShortcut} shortcut
     * @return string
     */
    getShortcutDescription = (shortcut, ignoreEnableState = false) => {
        if (shortcut === null) {
            return '';
        }

        if (!ignoreEnableState && !shortcut.enabled) {
            return t('global_inactive');
        }

        const labels = [];

        if (shortcut.ctrlKey === true) {
            labels.push(t('key_Control'));
        }

        if (shortcut.shiftKey === true) {
            labels.push(t('key_Shift'));
        }

        if (shortcut.metaKey === true) {
            labels.push(t('key_Meta'));
        }

        if (
            typeof shortcut.code !== 'undefined' &&
            ['ControlLeft', 'ControlRight', 'ShiftLeft', 'ShiftRight'].indexOf(shortcut.code) < 0
        ) {
            if (shortcut.code.toLowerCase().indexOf('meta') >= 0) {
                labels.push(t('key_Meta'));
            } else {
                const translated = t(`key_${shortcut.code}`);

                if (translated.indexOf('key_') >= 0) {
                    Log.warn('SETTINGS STORE', `The key ${shortcut.code} has no translation`);
                    labels.push(shortcut.code);
                } else {
                    labels.push(translated);
                }
            }
        }

        return labels.join(' + ');
    };
}
// the handler
export const settingsSliceHandler = SettingsSliceHandler.getInstance();
// the selectors to be used in useSelect
export const selectDataIsLoading = (state) => state.settingsSlice.dataIsLoading;
export const selectDataIsUpdating = (state) => state.settingsSlice.dataIsUpdating;
export const selectShortcutEditorData = (state) => state.settingsSlice.shortcutEditorData;
export const selectKeyboardShortcuts = (state) => state.settingsSlice.settings.keyboardShortcuts;
export const selectCollapsedSections = (state) => state.settingsSlice.collapsedSections;
export const selectRecordedShortcut = (state) => state.settingsSlice.recordedShortcut;
// other named exports
export const { toggleSectionCollapse, setEditedShortcut, setDeletedShortcut } = settingsSliceHandler.slice.actions;
export const { deleteShortcut, saveEditedShortcut, getShortcutDescription } = settingsSliceHandler;
