import { createSlice } from '@reduxjs/toolkit';

import AbstractSliceHandler from 'modules/shared/stores/abstract-slice-handler';
import LocalEvent from 'utils/local-event';
import { TabTypes, TransferListTypes, TransferListAreas } from 'constants/user-groups-constants';
import Log from 'utils/log';
import sdkClient from 'utils/sdk-client';
import { sortByProperty } from 'utils/functions';
import { addToaster } from 'utils/toasters-manager';
import t from 'utils/translate';
import { contactsSliceHandler } from './contacts-slice-handler';

const initialState = {
    allowPriority: false,
    priorityLevels: [],
    dataIsUpdating: true,
    userIsAdmin: true,
    /**
     * The search term for all areas involved in the transfer list
     */
    search: {
        leftUserTerm: '',
        rightUserTerm: '',
        leftAdminTerm: '',
        rightAdminTerm: '',
    },
    searchInProgress: {
        leftUser: false,
        rightUser: false,
        leftAdmin: false,
        rightAdmin: false,
    },
    /**
     * Hard-code this here now.
     * If is the case can be loaded from somewhere else.
     */
    searchRecordsOnPage: 50,
    /**
     * The contacts (users or groups) which are displayed in each box from both sections (users or admins).
     */
    contacts: {
        users: {
            left: {
                subscribers: [],
                groups: [],
            },
            right: {
                subscribers: [],
                groups: [],
            },
        },
        admins: {
            left: {
                subscribers: [],
                groups: [],
            },
            right: {
                subscribers: [],
                groups: [],
            },
        },
    },
    /**
     * These are the results of searches performed on the right side (assigned items).
     * The search is done locally against the contacts listed on the right side for each section.
     */
    localSearchResults: {
        users: {
            subscribers: [],
            groups: [],
        },
        admins: {
            subscribers: [],
            groups: [],
        },
    },
    groupData: {
        groupId: null,
        priority: 0,
        label: '',
    },
    userGroupLeftTab: TabTypes.USERS_TAB,
    adminGroupLeftTab: TabTypes.USERS_TAB,
    userGroupRightTab: TabTypes.USERS_TAB,
    adminGroupRightTab: TabTypes.USERS_TAB,
};

class UserGroupsSliceHandler extends AbstractSliceHandler {
    static getInstance() {
        if (!UserGroupsSliceHandler.instance) {
            UserGroupsSliceHandler.instance = new UserGroupsSliceHandler();
        }

        return UserGroupsSliceHandler.instance;
    }

    constructor() {
        super('userGroupsSlice');

        this.searchDebouncer = null;

        LocalEvent.setListener(LocalEvent.eventTypes.SDK_CLIENT_AUTHENTICATED, () => {
            this.onSdkClientAuthenticated();
        });

        this.slice = createSlice({
            name: this.sliceName,
            initialState,
            reducers: {
                setUserIsAdmin(state, action) {
                    state.userIsAdmin = action.payload;
                },
                setDataIsUpdating(state, action) {
                    state.dataIsUpdating = action.payload;
                },
                setAllowPriority(state, action) {
                    state.allowPriority = action.payload;
                },
                setPriorityLevels(state, action) {
                    state.priorityLevels = action.payload;
                },
                setGroupLabel(state, action) {
                    state.groupData = {
                        ...state.groupData,
                        label: action.payload,
                    };
                },
                setChannelPriority(state, action) {
                    state.groupData = {
                        ...state.groupData,
                        priority: action.payload,
                    };
                },
                /**
                 * Set the contacts in the store.
                 * @param {object} state
                 * @param {object} action The payload of the action will contain:
                 * - sectionType = users or admins
                 * - areaType = left or right
                 * - entityType = users or groups
                 */
                setContacts(state, action) {
                    const p = action.payload;

                    sortByProperty(p.contacts, 'fullName');

                    state.contacts[p.sectionType][p.areaType][p.entityType] = p.contacts;
                },
                /**
                 * Set the local search results.
                 * @param {object} state
                 * @param {object} action The payload of the action will contain:
                 * - sectionType = users or admins
                 * - entityType = users or groups
                 */
                setLocalSearchResults(state, action) {
                    state.localSearchResults[action.payload.sectionType][action.payload.entityType] = [
                        ...action.payload.contacts,
                    ];
                },
                setUserGroupLeftTab(state, action) {
                    state.userGroupLeftTab = action.payload;
                },
                setUserGroupRightTab(state, action) {
                    state.userGroupRightTab = action.payload;
                },
                setAdminGroupLeftTab(state, action) {
                    state.adminGroupLeftTab = action.payload;
                },
                setAdminGroupRightTab(state, action) {
                    state.adminGroupRightTab = action.payload;
                },
                setSearch(state, action) {
                    state.search = { ...state.search, ...action.payload };
                },
                /**
                 * Set the flags which informs JSX that there is a search in progress in designated box.
                 * @param {object} state
                 * @param {object} action The payload of the action will contain:
                 * - sectionType = leftUser, rightUser, leftAdmin, rightAdmin
                 * - state = true/false
                 */
                setSearchInProgress(state, action) {
                    state.searchInProgress = { ...state.searchInProgress, ...action.payload };
                },
                setGroupData(state, action) {
                    state.groupData = {
                        ...state.groupData,
                        ...action.payload,
                    };
                },
            },
        });
    }

    /**
     * Update the search term.
     * @param {TransferListTypes} section The section where is done: users or admins.
     * @param {TransferListAreas} area The area where the search is made: left or right.
     * @param {string} searchTerm
     */
    updateSearch = (section, area, searchTerm) => {
        let searchObj = {};
        const ownState = this.getOwnState();
        let searchAction;

        if (section === TransferListTypes.LIST_SECTION_USERS && area === TransferListAreas.LEFT_LIST) {
            searchObj = { leftUserTerm: searchTerm };
            searchAction = () => {
                this.dispatch('setSearchInProgress', { leftUser: true });

                this.doSdkSearch(section)
                    .then((contacts) => {
                        this.dispatch('setSearchInProgress', { leftUser: false });

                        if (ownState.userGroupLeftTab === TabTypes.USERS_TAB) {
                            this.dispatch('setContacts', {
                                sectionType: 'users',
                                areaType: 'left',
                                entityType: 'subscribers',
                                contacts,
                            });
                        } else {
                            this.dispatch('setContacts', {
                                sectionType: 'users',
                                areaType: 'left',
                                entityType: 'groups',
                                contacts,
                            });
                        }
                    })
                    .catch(() => {
                        this.dispatch('setSearchInProgress', { leftUser: false });
                    });
            };
        } else if (section === TransferListTypes.LIST_SECTION_USERS && area === TransferListAreas.RIGHT_LIST) {
            searchObj = { rightUserTerm: searchTerm };
            searchAction = () => {
                this.dispatch('setSearchInProgress', { rightUser: true });

                this.doLocalSearch(section).then((contacts) => {
                    this.dispatch('setSearchInProgress', { rightUser: false });

                    if (ownState.userGroupRightTab === TabTypes.USERS_TAB) {
                        this.dispatch('setLocalSearchResults', {
                            sectionType: 'users',
                            entityType: 'subscribers',
                            contacts,
                        });
                    } else {
                        this.dispatch('setLocalSearchResults', {
                            sectionType: 'users',
                            entityType: 'groups',
                            contacts,
                        });
                    }
                });
            };
        } else if (section === TransferListTypes.LIST_SECTION_ADMINS && area === TransferListAreas.LEFT_LIST) {
            searchObj = { leftAdminTerm: searchTerm };
            searchAction = () => {
                this.dispatch('setSearchInProgress', { leftAdmin: true });

                this.doSdkSearch(section).then((contacts) => {
                    this.dispatch('setSearchInProgress', { leftAdmin: false });

                    if (ownState.adminGroupLeftTab === TabTypes.USERS_TAB) {
                        this.dispatch('setContacts', {
                            sectionType: 'admins',
                            areaType: 'left',
                            entityType: 'subscribers',
                            contacts,
                        });
                    } else {
                        this.dispatch('setContacts', {
                            sectionType: 'admins',
                            areaType: 'left',
                            entityType: 'groups',
                            contacts,
                        });
                    }
                });
            };
        } else if (section === TransferListTypes.LIST_SECTION_ADMINS && area === TransferListAreas.RIGHT_LIST) {
            searchObj = { rightAdminTerm: searchTerm };
            searchAction = () => {
                this.dispatch('setSearchInProgress', { rightAdmin: true });

                this.doLocalSearch(section).then((contacts) => {
                    this.dispatch('setSearchInProgress', { rightAdmin: false });

                    if (ownState.adminGroupRightTab === TabTypes.USERS_TAB) {
                        this.dispatch('setLocalSearchResults', {
                            sectionType: 'admins',
                            entityType: 'subscribers',
                            contacts,
                        });
                    } else {
                        this.dispatch('setLocalSearchResults', {
                            sectionType: 'admins',
                            entityType: 'groups',
                            contacts,
                        });
                    }
                });
            };
        } else {
            // never reach this
        }

        this.dispatch('setSearch', searchObj);

        // search reset, need no action
        if (searchTerm.length < 2) {
            return;
        }

        if (this.searchDebouncer !== null) {
            clearTimeout(this.searchDebouncer);
        }

        this.searchDebouncer = setTimeout(() => {
            this.searchDebouncer = null;
            searchAction();
        }, 200);
    };

    /**
     * Search for contacts in the SDK.
     * @param {TransferListTypes} section
     * @return Promise<JsonContact[]>
     */
    doSdkSearch = (section) =>
        new Promise((resolve, reject) => {
            const ownState = this.getOwnState();
            let filterMode;
            let keyword;

            if (section === TransferListTypes.LIST_SECTION_USERS) {
                if (ownState.userGroupLeftTab === TabTypes.USERS_TAB) {
                    filterMode = webchatSDK.STWContactFilter.SINGLE;
                } else {
                    filterMode = webchatSDK.STWContactFilter.GROUP;
                }

                keyword = ownState.search.leftUserTerm;
            }

            if (section === TransferListTypes.LIST_SECTION_ADMINS) {
                if (ownState.adminGroupLeftTab === TabTypes.USERS_TAB) {
                    filterMode = webchatSDK.STWContactFilter.SINGLE;
                } else {
                    filterMode = webchatSDK.STWContactFilter.GROUP;
                }

                keyword = ownState.search.leftAdminTerm;
            }

            if (keyword.length === 0) {
                resolve([]);

                return;
            }

            webchatSDK.STWContactManager.searchContacts(filterMode, 0, ownState.searchRecordsOnPage, {
                keyword,
            }).then(
                (response) => {
                    const result = [];

                    response.forEach((sdkEntity) => {
                        result.push(sdkClient.sdkEntityToJsonContact(sdkEntity));
                    });

                    resolve(result);
                },
                (err) => {
                    Log.error(
                        'USER GROUPS STORE',
                        `There was an error while searching for company contacts : ${JSON.stringify(err)}`,
                    );
                    reject();
                },
            );
        });

    /**
     * Search for contacts locally. In the admin sor users.
     * @param {TransferListTypes} section
     * @return Promise<JsonContact[]>
     */
    doLocalSearch = (section) =>
        new Promise((resolve) => {
            const ownState = this.getOwnState();
            const result = {};
            let target;
            let keyword;

            if (section === TransferListTypes.LIST_SECTION_USERS) {
                if (ownState.userGroupRightTab === TabTypes.USERS_TAB) {
                    target = ownState.contacts.users.right.subscribers;
                } else {
                    target = ownState.contacts.users.right.groups;
                }

                keyword = ownState.search.rightUserTerm;
            }

            if (section === TransferListTypes.LIST_SECTION_ADMINS) {
                if (ownState.adminGroupLeftTab === TabTypes.USERS_TAB) {
                    target = ownState.contacts.admins.right.subscribers;
                } else {
                    target = ownState.contacts.admins.right.groups;
                }

                keyword = ownState.search.rightAdminTerm;
            }

            keyword = keyword.toLowerCase();

            target.forEach(
                /**
                 * @param {JsonContact} sdkContact
                 */
                (sdkContact) => {
                    if (!sdkContact.isGroup) {
                        if (
                            sdkContact.msisdn.includes(keyword) ||
                            sdkContact.fullName.toLowerCase().includes(keyword)
                        ) {
                            result[sdkContact.msisdn] = sdkContact;
                        }
                    } else if (sdkContact.name.toLowerCase().includes(keyword)) {
                        result[sdkContact.groupId] = sdkContact;
                    }
                },
            );

            resolve(Object.values(result));
        });

    /**
     * Getter for contacts, for each box.
     * @param {TransferListTypes} section
     * @param {TransferListAreas} area
     * @param {TabTypes} tab
     * @return JsonContact[]
     */
    getContacts = (section, area, tab, fullList = false) => {
        const ownState = this.getOwnState();

        if (section === TransferListTypes.LIST_SECTION_USERS) {
            if (area === TransferListAreas.LEFT_LIST) {
                if (tab === TabTypes.USERS_TAB) {
                    return ownState.contacts.users.left.subscribers;
                }

                return ownState.contacts.users.left.groups;
            }

            if (area === TransferListAreas.RIGHT_LIST) {
                if (tab === TabTypes.USERS_TAB) {
                    return fullList || ownState.search.rightUserTerm.length === 0
                        ? ownState.contacts.users.right.subscribers
                        : ownState.localSearchResults.users.subscribers;
                }

                return fullList || ownState.search.rightUserTerm.length === 0
                    ? ownState.contacts.users.right.groups
                    : ownState.localSearchResults.users.groups;
            }
        }

        if (section === TransferListTypes.LIST_SECTION_ADMINS) {
            if (area === TransferListAreas.LEFT_LIST) {
                if (tab === TabTypes.USERS_TAB) {
                    return ownState.contacts.admins.left.subscribers;
                }

                return ownState.contacts.admins.left.groups;
            }

            if (area === TransferListAreas.RIGHT_LIST) {
                if (tab === TabTypes.USERS_TAB) {
                    return fullList || ownState.search.rightAdminTerm.length === 0
                        ? ownState.contacts.admins.right.subscribers
                        : ownState.localSearchResults.admins.subscribers;
                }

                return fullList || ownState.search.rightAdminTerm.length === 0
                    ? ownState.contacts.admins.right.groups
                    : ownState.localSearchResults.admins.groups;
            }
        }

        return [];
    };

    /**
     * Inject the members in store. Useful when editing groups to reset data between editions.
     * @param section
     */

    /**
     * Inject the members in store. Useful when editing groups to reset data between editions.
     * @param {TransferListTypes} section
     * @param {JsonUser[]} leftSubscribers
     * @param {JsonGroup[]} leftGroups
     * @param {JsonUser[]} rightSubscribers
     * @param {JsonGroup[]} rightGroups
     */
    injectMembersInStore = (section, leftSubscribers, leftGroups, rightSubscribers, rightGroups) => {
        const baseParamLeft = {
            sectionType: section === TransferListTypes.LIST_SECTION_USERS ? 'users' : 'admins',
            areaType: 'left',
        };
        const baseParamRight = {
            sectionType: section === TransferListTypes.LIST_SECTION_USERS ? 'users' : 'admins',
            areaType: 'right',
        };

        this.dispatchMultiple(
            this.action('setContacts', {
                ...baseParamLeft,
                entityType: 'subscribers',
                contacts: leftSubscribers,
            }),
            this.action('setContacts', {
                ...baseParamLeft,
                entityType: 'groups',
                contacts: leftGroups,
            }),
        );

        this.dispatchMultiple(
            this.action('setContacts', {
                ...baseParamRight,
                entityType: 'subscribers',
                contacts: rightSubscribers,
            }),
            this.action('setContacts', {
                ...baseParamRight,
                entityType: 'groups',
                contacts: rightGroups,
            }),
        );
    };

    /**
     * Reset the internal members.
     * @param {TransferListTypes} section
     */
    resetMembers = (section) => {
        this.injectMembersInStore(section, [], [], [], []);
    };

    /**
     * Add or remove members (Items from right side).
     * @param {TransferListTypes} section
     * @param {{added?: JsonContact[], removed?: JsonContact[]}} data
     */
    addRemoveMembers = (section, data) => {
        // sanity
        if (!data.added && !data.removed) {
            return;
        }

        const ownState = this.getOwnState();
        const contactsRight =
            section === TransferListTypes.LIST_SECTION_USERS
                ? ownState.contacts.users.right
                : ownState.contacts.admins.right;
        const contactsLeft =
            section === TransferListTypes.LIST_SECTION_USERS
                ? ownState.contacts.users.left
                : ownState.contacts.admins.left;
        const newRightContacts = {
            subscribers: [...contactsRight.subscribers],
            groups: [...contactsRight.groups],
        };
        const newLeftContacts = {
            subscribers: [...contactsLeft.subscribers],
            groups: [...contactsLeft.groups],
        };

        const findIndex = (arr, contact) => arr.findIndex((source) => source.contactId === contact.contactId);

        if (data.added) {
            // add in right and remove from left
            data.added.forEach((addedContact) => {
                if (!addedContact.isGroup) {
                    const existingIndex = findIndex(newRightContacts.subscribers, addedContact);

                    if (existingIndex >= 0) {
                        newRightContacts.subscribers[existingIndex] = addedContact;
                    } else {
                        newRightContacts.subscribers.push(addedContact);
                    }

                    const leftIndex = findIndex(newLeftContacts.subscribers, addedContact);

                    if (leftIndex >= 0) {
                        newLeftContacts.subscribers.splice(leftIndex, 1);
                    }
                }

                if (addedContact.isGroup) {
                    const existingIndex = findIndex(newRightContacts.groups, addedContact);

                    if (existingIndex >= 0) {
                        newRightContacts.groups[existingIndex] = addedContact;
                    } else {
                        newRightContacts.groups.push(addedContact);
                    }
                    const leftIndex = findIndex(newLeftContacts.groups, addedContact);

                    if (leftIndex >= 0) {
                        newLeftContacts.groups.splice(leftIndex, 1);
                    }
                }
            });
        }

        if (data.removed) {
            data.removed.forEach((removedContact) => {
                if (!removedContact.isGroup) {
                    // do not allow the user to remove itself from the list of administrators
                    if (removedContact.contactId === contactsSliceHandler.getLoggedInUser().msisdn) {
                        return;
                    }

                    const indexRight = findIndex(newRightContacts.subscribers, removedContact);
                    const indexLeft = findIndex(newLeftContacts.subscribers, removedContact);

                    if (indexRight >= 0) {
                        newRightContacts.subscribers.splice(indexRight, 1);
                    }

                    if (indexLeft < 0) {
                        newLeftContacts.subscribers.push(removedContact);
                    }
                } else {
                    const index = findIndex(newRightContacts.groups, removedContact);
                    const indexLeft = findIndex(newLeftContacts.groups, removedContact);

                    if (index >= 0) {
                        newRightContacts.groups.splice(index, 1);
                    }

                    if (indexLeft < 0) {
                        newLeftContacts.groups.push(removedContact);
                    }
                }
            });
        }

        this.injectMembersInStore(
            section,
            newLeftContacts.subscribers,
            newLeftContacts.groups,
            newRightContacts.subscribers,
            newRightContacts.groups,
        );
    };

    /**
     * Getter for the state of the search in progress, for each box.
     * @param {TransferListTypes} section
     * @param {TransferListAreas} area
     * @return boolean
     */
    getSearchInProgress = (section, area) => {
        const ownState = this.getOwnState();

        if (section === TransferListTypes.LIST_SECTION_USERS && area === TransferListAreas.LEFT_LIST) {
            return ownState.searchInProgress.leftUser;
        }

        if (section === TransferListTypes.LIST_SECTION_USERS && area === TransferListAreas.RIGHT_LIST) {
            return ownState.searchInProgress.rightUser;
        }

        if (section === TransferListTypes.LIST_SECTION_ADMINS && area === TransferListAreas.LEFT_LIST) {
            return ownState.searchInProgress.leftAdmin;
        }

        if (section === TransferListTypes.LIST_SECTION_ADMINS && area === TransferListAreas.RIGHT_LIST) {
            return ownState.searchInProgress.rightAdmin;
        }

        return false;
    };

    /**
     * Get the selected tab based on the section and area.
     * @param {TransferListTypes} section
     * @param {TransferListAreas} area
     */
    getSelectedTab = (section, area) => {
        const state = this.getOwnState();
        let selectedTab = TabTypes.USERS_TAB;

        switch (section) {
            case TransferListTypes.LIST_SECTION_USERS:
                if (area === TransferListAreas.LEFT_LIST) {
                    selectedTab = state.userGroupLeftTab;
                } else {
                    selectedTab = state.userGroupRightTab;
                }

                break;

            case TransferListTypes.LIST_SECTION_ADMINS:
                if (area === TransferListAreas.LEFT_LIST) {
                    selectedTab = state.adminGroupLeftTab;
                } else {
                    selectedTab = state.adminGroupRightTab;
                }
                break;

            default:
                // never reach this
                break;
        }

        return selectedTab;
    };

    /**
     * Inject in the store, the contacts which may have been selected from outside.
     * @param {JsonContact[]} contacts
     */
    setPreselectedContacts = (contacts) => {
        // sanity
        if (typeof contacts !== 'object' || contacts.length === 0) {
            return;
        }

        let jsonContacts = [];

        Log.debug('USER GROUPS STORE', `Adding in store ${contacts.length} pre selected contacts`);

        // this came from loki
        if (typeof contacts[0].isJsonContact === 'undefined' && typeof contacts[0].CugTimestamp) {
            Log.notice('USER GROUPS STORE', `The received contacts needs to have their information loaded`);

            contacts.forEach((lokiContact) => {
                jsonContacts.push(sdkClient.lokiEntityToJsonContact(lokiContact));
            });
        } else {
            jsonContacts = contacts;
        }

        this.addRemoveMembers(TransferListTypes.LIST_SECTION_USERS, {
            added: jsonContacts,
        });
    };

    /**
     * Set the id of the edited group. (Entry point in the form).
     * @param {number} groupId
     */
    setEditedGroupId = (groupId) => {
        this.resetMembers(TransferListTypes.LIST_SECTION_USERS);
        this.resetMembers(TransferListTypes.LIST_SECTION_ADMINS);

        this.dispatch('setDataIsUpdating', true);

        sdkClient.getCugSettings().then((cugSettings) => {
            if (!('AllowPriority' in cugSettings)) {
                Log.warn('USER GROUPS STORE', `The required parameter "AllowPriority" was not found in CUG settings`);
            } else {
                this.dispatch('setAllowPriority', cugSettings.AllowPriority);
            }

            // reset the group data
            this.dispatch('setGroupData', {
                ...initialState.groupData,
            });

            // CREATE form
            if (groupId === 0) {
                this.dispatch('setUserIsAdmin', true);

                this.addLoggedInAsAdmin();

                this.dispatch('setDataIsUpdating', false);

                return;
            }

            // UPDATE form
            // attempt to load group details from SDK (WITH the child groups info to prepopulate the groups list as well)
            webchatSDK.STWContactManager.getGroupDetails(groupId, null, false)
                .then((sdkGroup) => {
                    const group = sdkClient.sdkEntityToJsonContact(sdkGroup);
                    const loggedInUser = contactsSliceHandler.getLoggedInUser();
                    let userIsAdmin = false;
                    const groupData = {
                        groupId: group.groupId,
                        label: group.name,
                        priority: typeof group.priority !== 'undefined' ? group.priority : 0,
                    };

                    const usersData = {
                        added: [],
                    };
                    const adminsData = {
                        added: [],
                    };

                    group.groupMembers.forEach((jsonContact) => {
                        if (jsonContact.isUserGroupAdmin === true) {
                            adminsData.added.push(jsonContact);

                            if (jsonContact.contactId === loggedInUser.msisdn) {
                                userIsAdmin = true;
                            }
                        }

                        usersData.added.push(jsonContact);
                    });

                    this.dispatchMultiple(
                        this.action('setDataIsUpdating', false),
                        this.action('setUserIsAdmin', userIsAdmin),
                        this.action('setGroupData', groupData),
                    );

                    this.addRemoveMembers(TransferListTypes.LIST_SECTION_USERS, usersData);
                    this.addRemoveMembers(TransferListTypes.LIST_SECTION_ADMINS, adminsData);
                })
                .catch((err) => {
                    this.dispatch('setDataIsUpdating', false);

                    Log.error(
                        'USER GROUPS STORE',
                        `There was an error loading the details of the group ${groupId}: ${err.code}`,
                    );

                    this.treatSdkError('setEditedGroupId', err);
                });
        });
    };

    markDialogAsClosed = () => {
        LocalEvent.trigger(LocalEvent.eventTypes.USER_GROUPS_DIALOG_CLOSED);
    };

    /**
     * Persist the group data
     */
    persistGroup = () => {
        const state = this.getOwnState();
        /**
         * @type {UserGroup}
         */
        const groupToSave = {
            GroupId: state.groupData.groupId,
            Name: state.groupData.label,
            Priority: state.groupData.priority,
            Members: [],
            IsUserGroup: true,
        };

        const adminIds = [];
        state.contacts.admins.right.subscribers.forEach(
            /**
             * @param {JsonUser} userAdmin
             */
            (userAdmin) => {
                adminIds.push(userAdmin.msisdn);
                groupToSave.Members.push({
                    Msisdn: userAdmin.msisdn,
                    IsUserGroupAdmin: true,
                });
            },
        );

        state.contacts.admins.right.groups.forEach(
            /**
             * @param {JsonGroup} userAdmin
             */
            (userAdmin) => {
                adminIds.push(userAdmin.groupId);
                groupToSave.Members.push({
                    GroupId: userAdmin.groupId,
                    IsUserGroupAdmin: true,
                });
            },
        );

        state.contacts.users.right.subscribers.forEach(
            /**
             * @param {JsonUser} userAdmin
             */
            (userAdmin) => {
                if (adminIds.includes(userAdmin.msisdn)) {
                    return;
                }

                groupToSave.Members.push({
                    Msisdn: userAdmin.msisdn,
                    IsUserGroupAdmin: false,
                });
            },
        );

        state.contacts.users.right.groups.forEach(
            /**
             * @param {JsonGroup} userAdmin
             */
            (userAdmin) => {
                if (adminIds.includes(userAdmin.groupId)) {
                    return;
                }

                groupToSave.Members.push({
                    GroupId: userAdmin.groupId,
                    IsUserGroupAdmin: false,
                });
            },
        );

        groupToSave.Members = groupToSave.Members.map(
            (rawMember) => new webchatSDK.Contact(rawMember, rawMember.GroupId ? 'group' : 'subscriber'),
        );

        const groupSaveOk = () => {
            this.markDialogAsClosed();
            addToaster({
                message: t('manage_user_groups_group_saved'),
                type: 'notice',
            });
        };

        const groupSaveNOk = (sdkError) => {
            this.treatSdkError('persistGroup', sdkError);
            this.markDialogAsClosed();
        };

        // the SDK is expecting a contact entity
        const sdkContact = new webchatSDK.Contact(groupToSave, 'group');

        if (groupToSave.GroupId === null) {
            webchatSDK.STWContactManager.createUserGroup(sdkContact)
                .then(() => {
                    groupSaveOk();
                    LocalEvent.trigger(LocalEvent.eventTypes.USER_GROUP_CREATED);
                })
                .catch((sdkError) => {
                    groupSaveNOk(sdkError);
                });
        } else {
            webchatSDK.STWContactManager.updateUserGroup(sdkContact)
                .then(() => {
                    groupSaveOk();
                    LocalEvent.trigger(LocalEvent.eventTypes.USER_GROUP_UPDATED, groupToSave.GroupId);
                })
                .catch((sdkError) => {
                    groupSaveNOk(sdkError);
                });
        }
    };

    addLoggedInAsAdmin = () => {
        const loggedInUser = contactsSliceHandler.getLoggedInUser();
        this.addRemoveMembers(TransferListTypes.LIST_SECTION_USERS, {
            added: [loggedInUser],
        });
        this.addRemoveMembers(TransferListTypes.LIST_SECTION_ADMINS, {
            added: [loggedInUser],
        });
    };

    onSdkClientAuthenticated = () => {
        sdkClient.getConfigurationSettings().then((configurationSettings) => {
            this.addLoggedInAsAdmin();

            if (!('userGroupsPrioritiesList' in configurationSettings)) {
                Log.warn(
                    'USER GROUPS STORE',
                    `The required parameter "userGroupsPrioritiesList" was not found in configuration settings`,
                );

                return;
            }

            this.dispatch(
                'setPriorityLevels',
                configurationSettings.userGroupsPrioritiesList.split(',').map((p) => Number(p)),
            );
        });
    };
}

// the handler
export const userGroupsSliceHandler = UserGroupsSliceHandler.getInstance();
// the selectors to be used in useSelect
export const selectUserIsAdmin = (state) => state.userGroupsSlice.userIsAdmin;
export const selectDataIsUpdating = (state) => state.userGroupsSlice.dataIsUpdating;
export const selectAllowPriority = (state) => state.userGroupsSlice.allowPriority;
export const selectPriorityLevels = (state) => state.userGroupsSlice.priorityLevels;
export const selectGroupData = (state) => state.userGroupsSlice.groupData;
export const selectSearch = (state) => state.userGroupsSlice.search;
// other named exports
export const {
    setGroupLabel,
    setChannelPriority,
    setUserGroupLeftTab,
    setUserGroupRightTab,
    setAdminGroupLeftTab,
    setAdminGroupRightTab,
} = userGroupsSliceHandler.slice.actions;
export const {
    updateSearch,
    getContacts,
    addRemoveMembers,
    getSelectedTab,
    getSearchInProgress,
    setPreselectedContacts,
    setEditedGroupId,
    markDialogAsClosed,
    persistGroup,
} = userGroupsSliceHandler;
