import * as React from 'react';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import t from 'utils/translate';
import SimpleModal from './simple-modal';

function InformationDialog({ infoMessage, title, closeCallback, ackText }) {
    const dialogTitle = typeof title !== 'undefined' ? title : t('js_all_information');
    const ackButtonText = typeof ackText !== 'undefined' ? ackText : t('js_all_ok_button');

    const safeClose = () => {
        if (typeof closeCallback !== 'function') {
            return;
        }

        closeCallback();
    };

    return (
        <SimpleModal
            dialogTitle={dialogTitle}
            closeCallback={safeClose}
            buttons={
                <div className={'information-dialog-buttons'}>
                    <Button variant="contained" role="primary" onClick={() => safeClose()}>
                        {ackButtonText}
                    </Button>
                </div>
            }
        >
            <DialogContentText>
                <span className={'information-dialog-question'}>{infoMessage}</span>
            </DialogContentText>
        </SimpleModal>
    );
}

export default InformationDialog;
