import moment from 'moment';
import Log from './log';
import LayoutManager from './layout-manager';

/**
 * Just a simple utility that allows quick access to locales.
 * @param label
 * @return {string}
 */
export default function t(label) {
    let result = label;

    const locales = LayoutManager.getLocales();

    if (typeof locales[label] === 'undefined') {
        Log.warn('TRANSLATE', `The label ${label} has no translation.`);
    } else {
        result = locales[label];
    }

    return result;
}

/**
 * Inject translations in the components that support and requires locales.
 */
export function injectEnvironmentLocales() {
    // momentjs

    // must convert our locales to momentjs ones
    const localesLinks = {
        ar_AE: 'ar',
        cs_CZ: 'cs',
        de_DE: 'de',
        en_GB: 'en-gb',
        en_US: 'en-gb',
        es_ES: 'es',
        fr_FR: 'fr',
        it_IT: 'it',
        ko_KR: 'ko',
        pt_BR: 'pt',
        sk_SK: 'sk',
        zh_TW: 'zh-tw',
        hu_HU: 'hu',
        ja_JP: 'ja',
    };
    const currentLanguage = LayoutManager.getLanguage();
    let momentLocale;

    if (typeof localesLinks[currentLanguage] !== 'undefined') {
        momentLocale = localesLinks[currentLanguage];
    } else {
        Log.warn('ENV LOCALES', `The current language ${currentLanguage} has no momentjs locale link. Will use en-gb`);

        momentLocale = 'en-gb';
    }

    moment.locale(momentLocale);

    // - when updating the moment's locale, the order of week days is the same, regardless of the first day of the week
    // - the week days locales expects array with 7 elements, where the first element is SUNDAY https://momentjs.com/docs/#/customization/
    // - but, OUR locales defines on first position MONDAY and that leads to de-synchronization: https://jira.streamwide.com/browse/SMARTMS-42862

    /**
     * @param string[] arr
     */
    const fixWeekDaysOrder = (arr) => {
        arr.unshift(arr.pop());
    };

    const translatedWeekDaysLong = t('js_all_days_long_names').split(',');
    const translatedWeekDaysShort = t('js_all_days_short_names').split(',');

    fixWeekDaysOrder(translatedWeekDaysLong);
    fixWeekDaysOrder(translatedWeekDaysShort);

    moment.updateLocale(momentLocale, {
        months: t('js_all_months_long_names').split(','),
        monthsShort: t('js_all_months_short_names').split(','),
        weekdays: translatedWeekDaysLong,
        weekdaysShort: translatedWeekDaysShort,
        weekdaysMin: translatedWeekDaysShort,
        week: {
            dow: wcfUiCfg.dateFormats.weekFirstDay,
        },
    });
}
