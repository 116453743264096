/**
 * The actual writing function.
 * (For now will use the legacy one because encapsulates everything, including remote log processing)
 * @param context
 * @param msg
 * @param level
 */
const writeLog = (context, msg, level) => {
    let line = `WCF-UI|${context}: ${msg}`;

    if (typeof window.log !== 'undefined') {
        window.log.log(level, line);

        return;
    }

    const d = new Date();
    const timestamp = `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}.${d.getMilliseconds()}`.padEnd(12, ' ');

    line = `${timestamp}|${line}`;

    // fallback used when the app runs standalone, not embedded under WebChat
    if (level === 'ERROR') {
        console.log(`%c${line}`, 'color:red');
    } else if (['WARNING', 'WARN'].indexOf(level) >= 0) {
        console.log(`%c${line}`, 'color:orange');
    } else if (level === 'NOTICE') {
        console.log(`%c${line}`, 'color:green');
    } else {
        console.log(line);
    }
};

/**
 * This class is used to log things at console.
 * The log will always be enabled in order to be able to get the logs from the very beginning of the app.
 */
class Log {
    static getInstance() {
        if (typeof Log.instance !== 'undefined') {
            return Log.instance;
        }

        Log.instance = new Log();

        return Log.instance;
    }

    /**
     * Write a log on debug level.
     * @param {string} context The context where the log is written. Ex: contacts-store
     * @param {string} msg
     */
    debug(context, msg) {
        writeLog(context, msg, 'DEBUG');
    }

    /**
     * Write a log on notice level.
     * @param {string} context The context where the log is written. Ex: contacts-store
     * @param {string} msg
     */
    notice(context, msg) {
        writeLog(context, msg, 'NOTICE');
    }

    /**
     * Write a log on warning level.
     * @param {string} context The context where the log is written. Ex: contacts-store
     * @param {string} msg
     */
    warn(context, msg) {
        writeLog(context, msg, 'WARNING');
    }

    /**
     * Write a log on error level.
     * @param {string} context The context where the log is written. Ex: contacts-store
     * @param {string} msg
     */
    error(context, msg) {
        writeLog(context, msg, 'ERROR');
    }
}

export default Log.getInstance();
