import * as React from 'react';
import Log from 'utils/log';
import CookiesUtil from 'utils/cookies-util';
import t from 'utils/translate';
import InformationDialog from 'modules/shared/components/information-dialog';
import { useEffect, useState } from 'react';

/**
 * This is a simple class which controls the appearance of the dialog which should inform the users about
 * cache clearing action that should be done upon an upgrade.
 */
class CacheClearChecker {
    constructor() {
        this.packageVersion = typeof wcfUiCfg.packageVersion !== 'undefined' ? wcfUiCfg.packageVersion : '0.0.0';
        this.cacheClearWarningType =
            typeof wcfUiCfg.cacheClearWarningType !== 'undefined' ? wcfUiCfg.cacheClearWarningType : null;
    }

    checkUserWarning() {
        const cookieName = 'stwv';

        // nothing to do
        if (this.cacheClearWarningType === 'none') {
            Log.debug('CACHE CLEAR DIALOG', 'The warning is disabled by config.');
            return false;
        }

        // check if the cookie exists
        const cachedVersion = CookiesUtil.getCookie(cookieName);

        const setCookie = () => {
            CookiesUtil.setCookie(cookieName, this.packageVersion);
        };

        if (cachedVersion === null) {
            Log.notice(
                'CACHE CLEAR DIALOG',
                `The cookie was not found. Will set it with current value ${this.packageVersion}. No warning.`,
            );

            // set the cookie and show the dialog; cookie is not present, then, most likely there is a fresh install, so no warning needed
            setCookie();

            return false;
        }

        if (cachedVersion === this.packageVersion) {
            Log.debug(
                'CACHE CLEAR DIALOG',
                `The version saved in cookie ${cachedVersion} is the same as current version => no warning.`,
            );

            // need no dialog; was shown before for this version.
            return false;
        }

        // check if it should be shown
        // the package version should have this format: version.major.minor.patch, Ex: 4.0.45.1698078445
        const cachedData = cachedVersion.split('.').map((v) => parseInt(v));

        if (cachedData.length !== 4) {
            Log.notice(
                'CACHE CLEAR DIALOG',
                `Invalid cookie value detected. Will set with the current value ${this.packageVersion}. No warning.`,
            );

            setCookie();

            return false;
        }

        // determine the upgrade type
        const versionData = this.packageVersion.split('.').map((v) => parseInt(v));

        let upgradeType = null;

        if (versionData[0] > cachedData[0] || versionData[1] > cachedData[1]) {
            upgradeType = 'major';
        } else if (versionData[2] > cachedData[2]) {
            upgradeType = 'minor';
        } else if (versionData[3] > cachedData[3]) {
            upgradeType = 'patch';
        } else {
            // invalid case; should never happen
            Log.notice(
                'CACHE CLEAR DIALOG',
                `Invalid cookie value detected. Cannot determine the needed upgrade type.`,
            );
            setCookie();

            return false;
        }

        let shouldShowWarning = false;

        switch (this.cacheClearWarningType) {
            case 'patch':
                // basically any type of upgrade will show it
                if (['major', 'minor', 'patch'].includes(upgradeType)) {
                    shouldShowWarning = true;
                }
                break;

            case 'minor':
                if (['major', 'minor'].includes(upgradeType)) {
                    shouldShowWarning = true;
                }
                break;

            case 'major':
                if (upgradeType === 'major') {
                    shouldShowWarning = true;
                }
                break;

            default:
                // never reach this
                break;
        }

        // prevent showing this next time
        setCookie();

        if (shouldShowWarning) {
            Log.debug(
                'CACHE CLEAR DIALOG',
                `Will show the warning because the configured type "${this.cacheClearWarningType}" matches with current upgrade type "${upgradeType}"`,
            );

            return true;
        }

        return false;
    }
}

function CacheClearWarning() {
    const [shouldShowWarning, setShouldShowWarning] = useState(false);

    useEffect(() => {
        setShouldShowWarning(new CacheClearChecker().checkUserWarning());
    }, []);

    if (!shouldShowWarning) {
        return '';
    }

    return (
        <InformationDialog
            infoMessage={t('js_all_cache_clear_warning')}
            closeCallback={() => setShouldShowWarning(false)}
        />
    );
}

export default CacheClearWarning;
