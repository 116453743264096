const LocalEventTypes = {
    /**
     * This event is triggered by the LayoutManager when initial scripts (JS or CSS) have been loaded.
     * Event receives no parameters.
     * @type {string}
     */
    LAYOUT_MANAGER_INITIAL_SCRIPTS_LOADED: 'LAYOUT_MANAGER_INITIAL_SCRIPTS_LOADED',
    /**
     * This event is triggered by the LayoutManager when an update has been started.
     * The update concerns: css direction and language.
     * Event receives no parameters.
     * @type {string}
     */
    LAYOUT_MANAGER_UPDATE_IN_PROGRESS: 'LAYOUT_MANAGER_UPDATE_IN_PROGRESS',
    /**
     * This event is triggered by the LayoutManager when the css files that defines the CSS direction have been loaded.
     * Event receives no parameters.
     * @type {string}
     */
    LAYOUT_MANAGER_CSS_DIRECTION_UPDATED: 'LAYOUT_MANAGER_CSS_DIRECTION_UPDATED',
    /**
     * This event is triggered by the LayoutManager when the script associated with the language has been loaded.
     * Event receives no parameters.
     * @type {string}
     */
    LAYOUT_MANAGER_LANGUAGE_UPDATED: 'LAYOUT_MANAGER_LANGUAGE_UPDATED',
    /**
     * This event is triggered by the SdkClient when has authenticated with SDK.
     * Event receives no parameters.
     * @type {string}
     */
    SDK_CLIENT_AUTHENTICATED: 'SDK_CLIENT_AUTHENTICATED',
    /**
     * This event is triggered by the SdkClient when the connection has been set with the SDK, but there
     * iis no authentication data (in app config, cookies, ...).
     * Event receives no parameters.
     * @type {string}
     */
    SDK_CLIENT_READY_TO_AUTHENTICATE: 'SDK_CLIENT_READY_TO_AUTHENTICATE',
    /**
     * This event is triggered by the Settings store when the key that has been pressed is matching with a keyboard shortcut
     * Event receives as parameter the matched shortcut.
     * @type {string}
     */
    SHORTCUT_KEY_PRESSED: 'SHORTCUT_KEY_PRESSED',
    /**
     * This event is triggered by the Settings store when the changes related with certain shortcut has been saved.
     * Event receives as parameter the updated shortcut.
     * @type {string}
     */
    SHORTCUT_KEY_SAVED: 'SHORTCUT_KEY_SAVED',
    /**
     * This event is triggered by the MeetingsRoom store when the use has chosen to share a meeting room.
     * Event receives as parameter the meeting room share details.
     * @type {string}
     */
    MEETING_ROOM_SHARED: 'MEETING_ROOM_SHARED',
    /**
     * This event is triggered by the MeetingsRoom store when the user wants to create a secure meeting room (video-conf).
     * Event receives as parameter the meeting room details.
     * @type {string}
     */
    MEETING_ROOM_SECURE_CREATED: 'MEETING_ROOM_SECURE_CREATED',
    /**
     * This event is triggered by the UserGroups store when the user has ended the edit/create action
     * by cancelling or saving.
     * Event receives no parameters.
     * @type {string}
     */
    USER_GROUPS_DIALOG_CLOSED: 'USER_GROUPS_DIALOG_CLOSED',
    /**
     * This event is triggered by the UserGroups store when a user group is created.
     * The event will receive no parameters
     */
    USER_GROUP_CREATED: 'USER_GROUP_CREATED',
    /**
     * This event is triggered by the UserGroups store when a user group is updated.
     * The event will receive the updated user group id as parameter
     */
    USER_GROUP_UPDATED: 'USER_GROUP_UPDATED',
    /**
     * This event is triggered by the VideoCallPopup when its firsts rendering has occurred.
     * Event receives as parameters: sessionId and the reference to the pupup content
     */
    VIDEO_CALL_POPUP_OPENED: 'VIDEO_CALL_POPUP_OPENED',
    /**
     * This event is triggered by the VideoStreamingSliceHandler when the state of a streamer object has been changed.
     * Event receives as parameters:
     * - streamer => the created streamer element which is set in store and has inside reference to its voip stack
     */
    VIDEO_STREAM_STREAMER_UPDATED: 'VIDEO_STREAM_STREAMER_UPDATED',
};
export default LocalEventTypes;
